import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { sentenceCase } from 'change-case';
// @mui
import {
    Grid,
    Card,
    Table,
    Stack,
    Paper,
    TableRow,
    MenuItem,
    TableBody,
    TableHead,
    TableCell,
    Container,
    Typography,
    TextField,
    TableContainer,
    TablePagination,
    OutlinedInput,

} from '@mui/material';

import moment from 'moment';
import { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Oval } from 'react-loader-spinner'
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import Label from '../../components/label';
import Scrollbar from '../../components/scrollbar';
import { BaseURL } from '../../common/Base_Url'

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': {
        width: 320,
        boxShadow: theme.customShadows.z8,
    },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
    },
}));

export default function SalonServices() {
    const { id } = useParams();

    const [selectedCategory, setSelectedCategory] = useState([]);

    const [allServices, setAllServices] = useState([])
    const [offerTitle, setOfferTitle] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [selectedServices, setSelectedServices] = useState([]);
    const COST_OF_SELECTED_SERVICE = selectedServices.length === 0 ? "" : selectedServices.reduce((accumulator, current) => accumulator + current.cost, 0);

    const [offerPrice, setOfferPrice] = useState("");
    const [salonDetails, setSalonDetails] = useState([]);

    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [query, setQuery] = useState('');


    const [TABLE_DATA, setTabledata] = useState([]);


    useEffect(() => {
        getSalonDetails();
        getAllAssignServices();
        getAllOffers();
    }, []);

    const getSalonDetails = () => {

        setLoading(true)

        const TOKEN = localStorage.getItem('currentUserAccessToken')
        const BODY = {
            salon_id: id
        }

        fetch(`${BaseURL}/getSalonByID`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Bearer ${TOKEN}`
            },
            body: JSON.stringify(BODY)
        }).then((response) => response.json())
            .then((responseJson) => {
                setSalonDetails(responseJson.Salons.data)
            })
            .catch((error) => {
                setLoading(false)
                alert(JSON.stringify(error));
            });

    };
    const getAllOffers = () => {

        setLoading(true)

        const TOKEN = localStorage.getItem('currentUserAccessToken')
        const BODY = {
            salon_id: id
        }

        fetch(`${BaseURL}/getSalonOffersAdmin`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Bearer ${TOKEN}`
            },
            body: JSON.stringify(BODY)
        }).then((response) => response.json())
            .then((responseJson) => {

                const DATA = []
                responseJson.Offers.forEach(element => {
                    DATA.push({
                        id: element.id,
                        title: element.offer_title,
                        price: element.offer_price,
                        startDate: element.offer_date_start,
                        endDate: element.offer_date_end,
                        services: JSON.parse(element.services_list),
                        salonDetails: element.salaon_details,
                        isFeatured: element.is_featured,
                        status: element.status,
                    })
                });

                setTabledata(DATA)
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
                alert(JSON.stringify(error));
            });

    };
    const getAllAssignServices = () => {

        setLoading(true)

        const TOKEN = localStorage.getItem('currentUserAccessToken')
        const BODY = {
            salon_id: id
        }

        fetch(`${BaseURL}/getAssignServicesToSalonAdmin`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Bearer ${TOKEN}`
            },
            body: JSON.stringify(BODY)
        }).then((response) => response.json())
            .then((responseJson) => {

                const DATA = []
                responseJson.Services.forEach(element => {
                    const value = parseFloat(element.cost.replace(",", "."));
                    DATA.push({
                        id: element.id,
                        categoryId: element.categoryId,
                        categoryName: element.categoryName,
                        name: element.name,
                        salon_id: element.salon_id,
                        time: element.time,
                        cost: value,
                    })
                });
                setAllServices(DATA)

                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
                alert(JSON.stringify(error));
            });

    };
    const handleClick = () => {

        if (offerTitle === "") {
            alert('Please Enter Offer Title')
        } else if (selectedServices.length === 0) {
            alert('Please Select Offer Services')
        } else if (offerPrice === "") {
            alert('Please Enter Offer Price')
        } else if (startDate === "null" || startDate === null) {
            alert('Please Enter Offer Start Time')
        } else if (endDate === "null" || endDate === null) {
            alert('Please Enter Offer End Time')
        } else if (salonDetails.length === 0) {
            alert('Please Enter Salon Details')
        } else {

            setLoading(true)

            const TOKEN = localStorage.getItem('currentUserAccessToken')

            const BODY = {
                salon_id: id,
                offer_title: offerTitle,
                offer_price: offerPrice,
                offer_date_start: startDate,
                offer_date_end: endDate,
                services_list: JSON.stringify(selectedServices),
                salaon_details: JSON.stringify(salonDetails),
                is_featured: "yes",
                status: "publish",
            }

            fetch(`${BaseURL}/assignOfferToSalon`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${TOKEN}`
                },
                body: JSON.stringify(BODY)
            }).then((response) => response.json())
                .then((responseJson) => {

                    setOfferTitle("")
                    setOfferPrice("")
                    setSelectedServices([]);
                    setStartDate(null)
                    setEndDate(null)

                    getAllOffers()
                    getAllAssignServices()
                    setLoading(false)
                })
                .catch((error) => {
                    alert(JSON.stringify(error));
                });

        }
    };
    const handleDelete = (ID) => {

        setLoading(true)

        const TOKEN = localStorage.getItem('currentUserAccessToken')

        const BODY = {
            id: ID,
        }

        fetch(`${BaseURL}/deleteSalonOfferAdmin`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Bearer ${TOKEN}`
            },
            body: JSON.stringify(BODY)
        }).then((response) => response.json())
            .then((responseJson) => {

                setLoading(false)

                if (responseJson.status === 200) {
                    getAllOffers();
                    getAllAssignServices();
                } else {
                    alert("Something Went Wrong Please Try Again");
                    getAllOffers();
                    getAllAssignServices();
                }

            })
            .catch((error) => {
                alert(JSON.stringify(error));
            });

    }
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const search = (data) => {
        return data.filter((item) => item.title.toLowerCase().includes(query))
    }

    const handleChange = (event) => {
        const value = event.target.value
        setSelectedServices(value)
    }

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - TABLE_DATA.length) : 0;
    const isNotFound = !TABLE_DATA.length && !!TABLE_DATA;

    return (
        <>
            <Helmet>
                <title> Services | EaseSalotto </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Offers
                    </Typography>
                    {loading === true ?
                        <Oval
                            height={30}
                            width={30}
                            color="#ff7e00"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={"true"}
                            ariaLabel='oval-loading'
                            secondaryColor="#2e3a59"
                            strokeWidth={2}
                            strokeWidthSecondary={2}

                        /> : null
                    }
                </Stack>


                <Card style={{ padding: 20 }}>
                    <Grid container spacing={3}>


                        <Grid item xs={12} sm={12} md={12}>

                            <TextField style={{ margin: 5 }} fullWidth value={offerTitle} onChange={(e) => setOfferTitle(e.target.value)} name="icon" label="Offer Title" />

                            <TextField
                                select
                                value={selectedServices}
                                onChange={handleChange}
                                SelectProps={{
                                    multiple: true
                                }}
                                fullWidth
                                helperText={`Services Cost ${COST_OF_SELECTED_SERVICE}`}
                                style={{ margin: 5, marginTop: 10 }} name="icon" label="Service">
                                {allServices.map((option) => (
                                    <MenuItem key={option.id} value={option}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <TextField style={{ margin: 5, marginTop: 10 }} fullWidth value={offerPrice} onChange={(e) => setOfferPrice(e.target.value)} name="icon" label="Offer Price" />

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Offer Start Date"
                                    value={startDate}
                                    onChange={(newValue) => {
                                        setStartDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField style={{ margin: 5, marginTop: 10 }} {...params} />}
                                />
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Offer End Date"
                                    value={endDate}
                                    onChange={(newValue) => {
                                        setEndDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField style={{ margin: 5, marginTop: 10 }} {...params} />}
                                />
                            </LocalizationProvider>

                            <LoadingButton onClick={() => handleClick()} style={{ width: 220, height: 55, margin: 5, marginTop: 10 }} fullWidth size="large" variant="contained">
                                Save
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Card>

                <div style={{ marginTop: 20 }}>
                    <Card>

                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 720 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ minWidth: 240 }}>Title</TableCell>
                                            <TableCell sx={{ minWidth: 160 }}>Price</TableCell>
                                            <TableCell sx={{ minWidth: 160 }}>Start Date</TableCell>
                                            <TableCell sx={{ minWidth: 160 }}>End Date</TableCell>
                                            <TableCell sx={{ minWidth: 160 }}>Services</TableCell>
                                            <TableCell sx={{ minWidth: 160 }}>Featured</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {TABLE_DATA.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {

                                            const { id, title, price, startDate, endDate, services, isFeatured } = row;

                                            return (
                                                <TableRow key={id}>
                                                    <TableCell>{title}</TableCell>
                                                    <TableCell>{price}</TableCell>
                                                    <TableCell>{moment(startDate).format('LL')}</TableCell>
                                                    <TableCell>{moment(endDate).format('LL')}</TableCell>
                                                    <TableCell>
                                                        {services.map((item) => {
                                                            return (
                                                                <div style={{ margin: 5 }}>
                                                                    <Label color={'default'}>{sentenceCase(item.name)}</Label>
                                                                </div>
                                                            )
                                                        })}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Label color={(isFeatured === 'no' && 'error') || 'success'}>{sentenceCase(isFeatured === 'no' ? 'No' : 'Yes')}</Label>
                                                    </TableCell>
                                                    <TableCell>
                                                        <LoadingButton onClick={() =>
                                                            confirmAlert({
                                                                title: 'Confirm to submit',
                                                                message: 'Are you sure to delete this service?',
                                                                buttons: [
                                                                    {
                                                                        label: 'Yes',
                                                                        onClick: () => handleDelete(id)
                                                                    },
                                                                    {
                                                                        label: 'No',
                                                                        onClick: () => console.log('NO')
                                                                    }
                                                                ]
                                                            })} style={{ width: 100, height: 30, backgroundColor: 'red' }} fullWidth size="large" variant="contained">
                                                            Delete
                                                        </LoadingButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}

                                    </TableBody>

                                    {isNotFound && (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                    <Paper
                                                        sx={{
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        <Typography variant="h6" paragraph>
                                                            Not found
                                                        </Typography>

                                                        <Typography variant="body2">
                                                            No results found for &nbsp;
                                                            <strong>&quot;{TABLE_DATA}&quot;</strong>.
                                                            <br /> Try checking for typos or using complete words.
                                                        </Typography>
                                                    </Paper>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}

                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={TABLE_DATA.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Card>
                </div>
            </Container>

        </>
    );
}
