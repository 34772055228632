import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async';

// @mui
import {
    Grid,
    Card,
    Table,
    Stack,
    Paper,
    TableRow,
    TableBody,
    TableHead,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    OutlinedInput,
    InputAdornment,
    TextField,
    MenuItem,
} from '@mui/material';
import { Oval } from 'react-loader-spinner'
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import Invoice from '../../components/Invoice'

export default function SalonBilling() {

    const { id } = useParams();

    const [loading, setLoading] = useState(false)
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [items, setitems] = useState([
        {
            title: 'Demo Title',
            description: 'Demo Description',
            qty: 1,
            price: 100
        }
    ]);


    const handleClick = () => {
        alert(fromDate + toDate)
        // if (selectedDay === "") {
        //     alert('Please Select Day')

        // } else if (timeFrom === "") {
        //     alert('Please Enter Time From')

        // } else if (timeTo === "") {
        //     alert('Please Enter Time To')

        // } else {

        //     setLoading(true)

        //     const TOKEN = localStorage.getItem('currentUserAccessToken')

        //     const BODY = {
        //         salon_id: id,
        //         day: selectedDay,
        //         time_from: timeFrom,
        //         time_to: timeTo,
        //         close_status: "0",
        //     }

        //     fetch(`${BaseURL}/updateSalonTimingAdmin`, {
        //         method: 'POST',
        //         headers: {
        //             'Accept': 'application/json',
        //             'Content-type': 'application/json',
        //             'Authorization': `Bearer ${TOKEN}`
        //         },
        //         body: JSON.stringify(BODY)
        //     }).then((response) => response.json())
        //         .then((responseJson) => {

        //             setLoading(false)


        //         })
        //         .catch((error) => {
        //             alert(JSON.stringify(error));
        //         });

        // }
    };

    return (
        <>
            <Helmet>
                <title> Salons | EaseSalotto </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Salon Billing
                    </Typography>
                    {loading === true ?
                        <Oval
                            height={30}
                            width={30}
                            color="#ff7e00"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={"true"}
                            ariaLabel='oval-loading'
                            secondaryColor="#2e3a59"
                            strokeWidth={2}
                            strokeWidthSecondary={2}

                        /> : null
                    }
                </Stack>

                <Stack spacing={3}>
                    <Card style={{ padding: 20 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12}>

                                <TextField type={"date"} style={{ marginLeft: 10 }} value={fromDate} onChange={((e) => setFromDate(e.target.value))} name="icon" />
                                <TextField type={"date"} style={{ marginLeft: 10 }} value={toDate} onChange={((e) => setToDate(e.target.value))} name="icon" />

                                <LoadingButton onClick={() => handleClick()} style={{ width: 150, height: 55, marginLeft: 20 }} fullWidth size="large" type="submit" variant="contained">
                                    Generate Bill
                                </LoadingButton>

                            </Grid>
                        </Grid>
                    </Card>
                </Stack>

                <Stack spacing={3}>
                    <Card style={{ marginTop: 10, padding: 20 }}>
                        <Invoice />
                    </Card>
                </Stack>


            </Container>

        </>
    );
}
