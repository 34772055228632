import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { BaseURL } from '../../../common/Base_Url'

// ----------------------------------------------------------------------

export default function LoginForm() {

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {

    const getToken = localStorage.getItem('currentUserAccessToken');
    const getUser = localStorage.getItem('currentUser');

    if (getToken && getUser) {
      navigate('/dashboard', { replace: true });
    } else {
      console.log('')
    }


  }, [])

  const handleClick = () => {

    const BODY = { email, password }

    fetch(`${BaseURL}/login`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json'
      },
      body: JSON.stringify(BODY)
    }).then((response) => response.json())
      .then((responseJson) => {

        if (responseJson.error) {
          alert('Invalid Email or Password', 'invalid');
          return;
        }

        if (responseJson.user.user_type !== "3") {
          alert('Invalid Email or Password', 'invalid');
          return;
        }

        localStorage.setItem('currentUserAccessToken', responseJson.access_token)
        localStorage.setItem('currentUser', JSON.stringify(responseJson.user))

        navigate('/dashboard', { replace: true });

      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });

  };

  return (
    <>
      <Stack spacing={3}>

        <TextField value={email} onChange={e => setEmail(e.target.value)} name="email" label="Email address" />

        <TextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={e => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <LoadingButton style={{ marginTop: 20 }} fullWidth size="large" type="submit" variant="contained" onClick={() => handleClick()}>
        Login
      </LoadingButton>
    </>
  );
}
