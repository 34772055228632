import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { sentenceCase } from 'change-case';
// @mui
import {
    Grid,
    Card,
    Table,
    Stack,
    Paper,
    TableRow,
    MenuItem,
    TableBody,
    TableHead,
    TableCell,
    Container,
    Typography,
    TextField,
    TableContainer,
    TablePagination,
    OutlinedInput,
    Button,
    Dialog,
    DialogActions,

} from '@mui/material';

import moment from 'moment';
import { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Oval } from 'react-loader-spinner'
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import Label from '../../components/label';
import Scrollbar from '../../components/scrollbar';
import { BaseURL } from '../../common/Base_Url'

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': {
        width: 320,
        boxShadow: theme.customShadows.z8,
    },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
    },
}));

export default function SalonAccountContactInformation() {

    const [open, setOpen] = useState(false);
    const { id } = useParams();

    const [PersonName, setPersonName] = useState("");
    const [ContactNumber, setContactNumber] = useState("");
    const [SecondContactNumber, setSecondContactNumber] = useState("");
    const [BankName, setBankName] = useState("");
    const [AccountTitle, setAccountTitle] = useState("");
    const [AccountNumber, setAccountNumber] = useState("");

    const [allServices, setAllServices] = useState([])
    const [offerTitle, setOfferTitle] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [selectedServices, setSelectedServices] = useState([]);
    const COST_OF_SELECTED_SERVICE = selectedServices.length === 0 ? "" : selectedServices.reduce((accumulator, current) => accumulator + current.cost, 0);

    const [offerPrice, setOfferPrice] = useState("");
    const [salonDetails, setSalonDetails] = useState([]);

    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [query, setQuery] = useState('');


    const [TABLE_DATA, setTabledata] = useState([]);


    useEffect(() => {
        getAllInformation();
    }, []);

    const getAllInformation = () => {

        setLoading(true)

        const TOKEN = localStorage.getItem('currentUserAccessToken')
        const BODY = {
            salon_id: id
        }

        fetch(`${BaseURL}/getSalonContactInformation`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Bearer ${TOKEN}`
            },
            body: JSON.stringify(BODY)
        }).then((response) => response.json())
            .then((responseJson) => {

                const DATA = []
                responseJson.Information.forEach(element => {
                    DATA.push({
                        id: element.id,
                        personName: element.personName,
                        contactNumber: element.contactNumber,
                        secondContactNumber: element.secondContactNumber,
                        bankName: element.bankName,
                        accountTitle: element.accountTitle,
                        accountNumber: element.accountNumber,
                    })
                });

                setTabledata(DATA)
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
                alert(JSON.stringify(error));
            });

    };
    const getAllAssignServices = () => {

        setLoading(true)

        const TOKEN = localStorage.getItem('currentUserAccessToken')
        const BODY = {
            salon_id: id
        }

        fetch(`${BaseURL}/getAssignServicesToSalonAdmin`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Bearer ${TOKEN}`
            },
            body: JSON.stringify(BODY)
        }).then((response) => response.json())
            .then((responseJson) => {

                const DATA = []
                responseJson.Services.forEach(element => {
                    const value = parseFloat(element.cost.replace(",", "."));
                    DATA.push({
                        id: element.id,
                        categoryId: element.categoryId,
                        categoryName: element.categoryName,
                        name: element.name,
                        salon_id: element.salon_id,
                        time: element.time,
                        cost: value,
                    })
                });
                setAllServices(DATA)

                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
                alert(JSON.stringify(error));
            });

    };

    const handleDelete = () => {

        setLoading(true)

        const TOKEN = localStorage.getItem('currentUserAccessToken')

        const BODY = {
            salon_id: id,
        }

        fetch(`${BaseURL}/deleteSalonContactInformation`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Bearer ${TOKEN}`
            },
            body: JSON.stringify(BODY)
        }).then((response) => response.json())
            .then((responseJson) => {

                setLoading(false)

                if (responseJson.status === 200) {
                    getAllInformation();
                } else {
                    alert("Something Went Wrong Please Try Again");
                    getAllInformation();
                }

            })
            .catch((error) => {
                alert(JSON.stringify(error));
            });

    }

    const handleClick = () => {

        if (PersonName === "") {
            alert('Please Enter Person Name');
        } else if (ContactNumber === "") {
            alert('Please Enter Contact Number');
        } else if (SecondContactNumber === "") {
            alert('Please Enter Second Contact Number');
        } else if (BankName === "") {
            alert('Please Enter Bank Name');
        } else if (AccountTitle === "") {
            alert('Please Enter Account Title');
        } else if (AccountNumber === "") {
            alert('Please Enter Account Number');
        } else {

            setLoading(true)

            const TOKEN = localStorage.getItem('currentUserAccessToken')

            const BODY = {
                salon_id: id,
                personName: PersonName,
                contactNumber: ContactNumber,
                secondContactNumber: SecondContactNumber,
                bankName: BankName,
                accountTitle: AccountTitle,
                accountNumber: AccountNumber,
            }

            fetch(`${BaseURL}/createSalonContactInformation`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${TOKEN}`
                },
                body: JSON.stringify(BODY)
            }).then((response) => response.json())
                .then((responseJson) => {

                    setLoading(false)
                    if (responseJson.status === 200) {
                        getAllInformation();
                        setOpen(false);
                    }
                })
                .catch((error) => {
                    alert(JSON.stringify(error));
                });

        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - TABLE_DATA.length) : 0;
    const isNotFound = !TABLE_DATA.length && !!TABLE_DATA;

    return (
        <>
            <Helmet>
                <title> Account Info | EaseSalotto </title>
            </Helmet>

            <Dialog
                scroll='body'
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <Card style={{ padding: 20 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12}>

                            <TextField style={{ margin: 5 }} fullWidth value={PersonName} onChange={(e) => setPersonName(e.target.value)} name="icon" label="Person Name" />
                            <TextField style={{ margin: 5 }} fullWidth value={ContactNumber} onChange={(e) => setContactNumber(e.target.value)} name="icon" label="Contact Number" />
                            <TextField style={{ margin: 5 }} fullWidth value={SecondContactNumber} onChange={(e) => setSecondContactNumber(e.target.value)} name="icon" label="Second Contact Number" />
                            <TextField style={{ margin: 5 }} fullWidth value={BankName} onChange={(e) => setBankName(e.target.value)} name="icon" label="Bank Name" />
                            <TextField style={{ margin: 5 }} fullWidth value={AccountTitle} onChange={(e) => setAccountTitle(e.target.value)} name="icon" label="Account Title" />
                            <TextField style={{ margin: 5 }} fullWidth value={AccountNumber} onChange={(e) => setAccountNumber(e.target.value)} name="icon" label="Account Number" />

                            <LoadingButton onClick={() => handleClick()} style={{ width: 220, height: 55, margin: 5, marginTop: 10 }} fullWidth size="large" variant="contained">
                                Create
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Card>

                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    {loading === true ?
                        <Oval
                            height={30}
                            width={30}
                            color="#ff7e00"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={"true"}
                            ariaLabel='oval-loading'
                            secondaryColor="#2e3a59"
                            strokeWidth={2}
                            strokeWidthSecondary={2}

                        /> : null
                    }
                </Stack>

                <div>
                    <Card>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid xs={11} md={11}>
                                <div style={{ marginTop: 20, marginBottom: 20, marginLeft: 40 }}>
                                    <Typography variant="h4" gutterBottom>
                                        Bank Account & Contact Information
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid xs={1} md={1}>
                                <Button variant="outlined" style={{ marginTop: 20 }} onClick={handleClickOpen}>
                                    Create
                                </Button>
                            </Grid>
                        </Grid>
                    </Card>
                </div>

                <div style={{ marginTop: 20 }}>
                    <Card>

                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 720 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ minWidth: 160 }}>Person Name</TableCell>
                                            <TableCell sx={{ minWidth: 160 }}>Contact Number</TableCell>
                                            <TableCell sx={{ minWidth: 160 }}>Second Number</TableCell>
                                            <TableCell sx={{ minWidth: 160 }}>Bank Name</TableCell>
                                            <TableCell sx={{ minWidth: 160 }}>Account Title</TableCell>
                                            <TableCell sx={{ minWidth: 160 }}>Account Number</TableCell>
                                            <TableCell sx={{ minWidth: 160 }}>Action</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {TABLE_DATA.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {

                                            const { id, personName, contactNumber, secondContactNumber, bankName, accountTitle, accountNumber } = row;

                                            return (
                                                <TableRow key={id}>
                                                    <TableCell>{personName}</TableCell>
                                                    <TableCell>{contactNumber}</TableCell>
                                                    <TableCell>{secondContactNumber}</TableCell>
                                                    <TableCell>{bankName}</TableCell>
                                                    <TableCell>{accountTitle}</TableCell>
                                                    <TableCell>{accountNumber}</TableCell>
                                                    <TableCell>
                                                        <LoadingButton onClick={() =>
                                                            confirmAlert({
                                                                title: 'Confirm to submit',
                                                                message: 'Are you sure to delete this information?',
                                                                buttons: [
                                                                    {
                                                                        label: 'Yes',
                                                                        onClick: () => handleDelete()
                                                                    },
                                                                    {
                                                                        label: 'No',
                                                                        onClick: () => console.log('NO')
                                                                    }
                                                                ]
                                                            })} style={{ width: 100, height: 30, backgroundColor: 'red' }} fullWidth size="large" variant="contained">
                                                            Delete
                                                        </LoadingButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}

                                    </TableBody>

                                    {isNotFound && (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                    <Paper
                                                        sx={{
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        <Typography variant="h6" paragraph>
                                                            Not found
                                                        </Typography>

                                                        <Typography variant="body2">
                                                            No results found for &nbsp;
                                                            <strong>&quot;{TABLE_DATA}&quot;</strong>.
                                                            <br /> Try checking for typos or using complete words.
                                                        </Typography>
                                                    </Paper>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}

                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={TABLE_DATA.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Card>
                </div>
            </Container>

        </>
    );
}
