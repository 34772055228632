import React from 'react'

// @mui
import {
    Grid,
    Card,
    Table,
    Stack,
    Paper,
    TableRow,
    TableBody,
    TableHead,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    OutlinedInput,
    InputAdornment,
    TextField,
    MenuItem,
} from '@mui/material';
import styles from './InvoiceStyle';

export default function Invoice() {

    const handlePrint = () => {
        window.print()
    }

    return (
        <>
            <main>

                <div style={styles.gridContainer}>
                    <div style={{ alignItems: 'flex-end', flexDirection: 'column' }}>
                        <p style={styles.h3}>pending</p>
                        <p >INV-444</p>
                    </div>
                </div>

                <div style={styles.gridContainer}>
                    <div style={styles.col6}>
                        <p style={styles.overline}>Invoice from</p>
                        <p style={styles.body1}>EaseSalotto</p>
                        <p style={styles.body1}>Lahore, Pakistan</p>
                        <p style={styles.body1}>+92 318 6266261</p>
                    </div>
                    <div style={styles.col6}>
                        <p style={styles.overline}>Invoice to</p>
                        <p style={styles.body1}>Chop Shop Salon</p>
                        <p style={styles.body1}>Multan, Pakistan</p>
                        <p style={styles.body1}>+92 303 9250823</p>
                    </div>
                </div>

                <p style={styles.overline}>Invoice Details</p>





                {/* Header */}
                <header className='flex flex-col items-center justify-center mb-5'>
                    <div>
                        <h2>Invoice</h2>
                    </div>
                    <div>
                        <ul>
                            <li><button onClick={() => handlePrint()}>Print</button></li>
                            <li>Download</li>
                            <li>Send</li>
                        </ul>
                    </div>
                </header>
                {/* End Of Header */}

                {/* Your Details */}
                <section>
                    <input type="text" name="text" id="text" placeholder="Enter your name" />
                    <h2>Thomas Sankara</h2>
                    <p>Your Address</p>
                </section>
                {/* End of Your Details */}

                {/* Client Details */}
                <section>
                    <h2>Client's Name</h2>
                    <p>Client's Address</p>
                </section>
                {/* End of Client Details */}

                {/* Dates Details */}
                <article>
                    <ul>
                        <li>Invoice no:</li>
                        <li>Invoice date:</li>
                        <li>Due date:</li>
                    </ul>
                </article>
                {/* End of Dates Details */}

                {/* Table Details */}
                <article>
                    <ul>
                        <li>Invoice no:</li>
                        <li>Invoice date:</li>
                        <li>Due date:</li>
                    </ul>
                </article>
                {/* End of Table Details */}

                {/* Notes Details */}
                <section>
                    <p>Notes to the client...</p>
                </section>
                {/* End of Notes Details */}

                {/* Footer Details */}
                <footer>
                    <ul>
                        <li>Your name</li>
                        <li>Your email</li>
                        <li>Phone number</li>
                        <li>Bank</li>
                        <li>Account holder</li>
                        <li>Account number</li>
                        <li>Website</li>
                    </ul>
                </footer>
                {/* End of Footer Details */}

            </main>
        </>
    )
}
