import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { sentenceCase } from 'change-case';
// @mui
import {
    Grid,
    Box,
    Card,
    Table,
    Paper,
    Popover,
    TableRow,
    Avatar,
    MenuItem,
    TableBody,
    TableHead,
    TableCell,
    Container,
    Typography,
    Stack,
    TextField,
    InputAdornment,
    IconButton,
    Button,
    TableContainer,
    TablePagination,
    OutlinedInput,
} from '@mui/material';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Oval } from 'react-loader-spinner'

import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { BaseURL } from '../../common/Base_Url'

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': {
        width: 320,
        boxShadow: theme.customShadows.z8,
    },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
    },
}));


export default function SalonTimings() {

    const { id } = useParams();
    const [loading, setLoading] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [selectedDay, setSelectedDay] = useState('');
    const [timeFrom, setTimeFrom] = useState('');
    const [timeTo, setTimeTo] = useState('');
    const [days, setDays] = useState([
        { value: 'Monday', label: 'Monday' },
        { value: 'Tuesday', label: 'Tuesday' },
        { value: 'Wednesday', label: 'Wednesday' },
        { value: 'Thursday', label: 'Thursday' },
        { value: 'Friday', label: 'Friday' },
        { value: 'Saturday', label: 'Saturday' },
        { value: 'Sunday', label: 'Sunday' },
    ]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [query, setQuery] = useState('');

    const [TABLE_DATA, setAllTimings] = useState([]);


    useEffect(() => {
        getSalonTimings()
    }, [])

    const getSalonTimings = () => {

        setLoading(true)

        const TOKEN = localStorage.getItem('currentUserAccessToken')

        const BODY = {
            salon_id: id
        }

        fetch(`${BaseURL}/getSalonTimingBySalonAdmin`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Bearer ${TOKEN}`
            },
            body: JSON.stringify(BODY)
        }).then((response) => response.json())
            .then((responseJson) => {

                const DATA = []
                responseJson.SalonTime.forEach(element => {
                    DATA.push({
                        id: element.id,
                        day: element.day,
                        timeFrom: element.time_from,
                        timeTo: element.time_to,
                        isClose: element.close_status,
                    })
                });

                setLoading(false)

                setAllTimings(DATA)

            })
            .catch((error) => {
                alert(JSON.stringify(error));
            });

    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const search = (data) => {
        return null
    }

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - search(TABLE_DATA).length) : 0;
    const isNotFound = !TABLE_DATA.length && !!TABLE_DATA;


    const handleClick = () => {

        if (selectedDay === "") {
            alert('Please Select Day')

        } else if (timeFrom === "") {
            alert('Please Enter Time From')

        } else if (timeTo === "") {
            alert('Please Enter Time To')

        } else {

            setLoading(true)

            const TOKEN = localStorage.getItem('currentUserAccessToken')

            const BODY = {
                salon_id: id,
                day: selectedDay,
                time_from: timeFrom,
                time_to: timeTo,
                close_status: "0",
            }

            fetch(`${BaseURL}/updateSalonTimingAdmin`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${TOKEN}`
                },
                body: JSON.stringify(BODY)
            }).then((response) => response.json())
                .then((responseJson) => {

                    setLoading(false)

                    if (responseJson.status === 200) {
                        getSalonTimings()
                    } else {
                        alert("Something Went Wrong Please Try Again");
                        getSalonTimings()
                    }

                })
                .catch((error) => {
                    alert(JSON.stringify(error));
                });

        }
    };

    const handleDelete = (ID) => {

        setLoading(true)

        const TOKEN = localStorage.getItem('currentUserAccessToken')

        const BODY = {
            id: ID,
            salon_id: id
        }

        fetch(`${BaseURL}/deleteSalonTimingAdmin`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Bearer ${TOKEN}`
            },
            body: JSON.stringify(BODY)
        }).then((response) => response.json())
            .then((responseJson) => {

                setLoading(false)

                if (responseJson.status === 200) {
                    getSalonTimings()
                } else {
                    alert("Something Went Wrong Please Try Again");
                    getSalonTimings()
                }

            })
            .catch((error) => {
                alert(JSON.stringify(error));
            });

    }

    const handleClose = (ID, CLOSE_STATUS) => {

        setLoading(true)

        const TOKEN = localStorage.getItem('currentUserAccessToken')

        const BODY = {
            id: ID,
            salon_id: id,
            close_status: CLOSE_STATUS === "1" ? "0" : "1"
        }

        fetch(`${BaseURL}/updateSalonTimingIsCloseStatusAdmin`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Bearer ${TOKEN}`
            },
            body: JSON.stringify(BODY)
        }).then((response) => response.json())
            .then((responseJson) => {

                setLoading(false)

                if (responseJson.status === 200) {
                    getSalonTimings()
                } else {
                    alert("Something Went Wrong Please Try Again");
                    getSalonTimings()
                }

            })
            .catch((error) => {
                alert(JSON.stringify(error));
            });

    }

    return (
        <>
            <Helmet>
                <title> Salons | EaseSalotto </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Salon Timings
                    </Typography>

                    {loading === true ?
                        <Oval
                            height={30}
                            width={30}
                            color="#ff7e00"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={"true"}
                            ariaLabel='oval-loading'
                            secondaryColor="#2e3a59"
                            strokeWidth={2}
                            strokeWidthSecondary={2}

                        /> : null
                    }

                </Stack>

                <Stack spacing={3}>

                    <Card style={{ padding: 20 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField style={{ marginLeft: 10 }} value={timeFrom} onChange={((e) => setTimeFrom(e.target.value))} name="icon" label="Time From" />
                                <TextField style={{ marginLeft: 10 }} value={timeTo} onChange={((e) => setTimeTo(e.target.value))} name="icon" label="Time To" />
                                <TextField select value={selectedDay} onChange={(e) => setSelectedDay(e.target.value)} style={{ marginLeft: 10, width: 150 }} name="icon" label="Day">
                                    {days.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>

                                <LoadingButton onClick={() => handleClick()} style={{ width: 220, height: 55, marginLeft: 20 }} fullWidth size="large" type="submit" variant="contained">
                                    Save
                                </LoadingButton>

                            </Grid>
                        </Grid>
                    </Card>

                </Stack>

                <div style={{ marginTop: 20 }}>
                    <Card>

                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 720 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ minWidth: 240 }}>Day</TableCell>
                                            <TableCell sx={{ minWidth: 160 }}>Time From</TableCell>
                                            <TableCell sx={{ minWidth: 160 }}>Time To</TableCell>
                                            <TableCell sx={{ minWidth: 120 }}>Close</TableCell>
                                            <TableCell sx={{ minWidth: 120 }}>Delete</TableCell>
                                            <TableCell sx={{ minWidth: 120 }}>Close Salon</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {TABLE_DATA.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {

                                            const { id, day, timeFrom, timeTo, isClose } = row;

                                            return (
                                                <TableRow key={id}>
                                                    <TableCell>{day}</TableCell>
                                                    <TableCell>{timeFrom}</TableCell>
                                                    <TableCell>{timeTo}</TableCell>
                                                    <TableCell align="left">
                                                        <Label color={isClose === '0' ? 'error' : 'success'}>{sentenceCase(isClose === '0' ? 'No' : 'Yes')}</Label>
                                                    </TableCell>
                                                    <TableCell>
                                                        <LoadingButton onClick={() =>
                                                            confirmAlert({
                                                                title: 'Confirm to submit',
                                                                message: 'Are you sure to delete this time?',
                                                                buttons: [
                                                                    {
                                                                        label: 'Yes',
                                                                        onClick: () => handleDelete(id)
                                                                    },
                                                                    {
                                                                        label: 'No',
                                                                        onClick: () => console.log('NO')
                                                                    }
                                                                ]
                                                            })} style={{ width: 50, height: 30, marginLeft: 20, backgroundColor: 'red' }} fullWidth size="large" type="submit" variant="contained">
                                                            Delete
                                                        </LoadingButton>
                                                    </TableCell>
                                                    <TableCell>
                                                        <LoadingButton onClick={() => handleClose(id, isClose)} style={{ width: 50, height: 30, marginLeft: 20, backgroundColor: 'red' }} fullWidth size="large" type="submit" variant="contained">
                                                            Close
                                                        </LoadingButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}

                                    </TableBody>

                                    {isNotFound && (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                    <Paper
                                                        sx={{
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        <Typography variant="h6" paragraph>
                                                            Not found
                                                        </Typography>

                                                        <Typography variant="body2">
                                                            No results found for &nbsp;
                                                            <strong>&quot;{TABLE_DATA}&quot;</strong>.
                                                            <br /> Try checking for typos or using complete words.
                                                        </Typography>
                                                    </Paper>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}

                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={TABLE_DATA.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Card>
                </div>
            </Container>

        </>
    );
}
