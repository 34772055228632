import { Helmet } from 'react-helmet-async';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// @mui
import {
  Box,
  Stack,
  Card,
  Table,
  Paper,
  Avatar,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  TableContainer,
  Container,
  TablePagination,
  OutlinedInput,
  InputAdornment,
  Grid,
  Button,
  Dialog,
  DialogActions,
  TextField,
  MenuItem
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Oval } from 'react-loader-spinner'
import axios from 'axios';
// components
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { styled, alpha } from '@mui/material/styles';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import SalonsTablePopUpMenu from './Salons/salonsTablePopUpMenu'
import UploadIllustration from '../assets/illustration_upload';
import { BaseURL, BaseURLImage } from '../common/Base_Url'

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));


export default function SalonPage() {

  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [query, setQuery] = useState('');
  const [TABLE_DATA, setAllSalons] = useState([]);
  const [salonImage, setSalonImage] = useState([]);
  const [Name, setName] = useState("");
  const [Latitude, setLatitude] = useState(0);
  const [Longitude, setLongitude] = useState(0);
  const [salonComission, setSalonComission] = useState(0);
  const [Address, setAddress] = useState("");
  const [City, setCity] = useState("");
  const [selectedGender, setSelectedGender] = useState('');
  const [gender, setGender] = useState([
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' }
  ]);
  const [comissionTypes, setComissionTypes] = useState([
    { value: 'percent', label: 'Percent' },
    { value: 'flat', label: 'Flat' }
  ]);
  const [salonTypes, setSalonTypes] = useState(["Beauty Salon", "Hair Salon", "Spa Services", "Barber Services", "Nail Salon"])
  const [allCities, setAllCities] = useState(["Multan"])
  const [selectedSalonTypes, setSelectedSalonTypes] = useState([]);
  const [selectedSalonComissionType, setSelectedSalonComissionType] = useState('');

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getAllSalons()
  }, []);

  const getAllSalons = () => {

    setLoading(true)

    const TOKEN = localStorage.getItem('currentUserAccessToken')
    const BODY = {}

    fetch(`${BaseURL}/getAllSalons`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Bearer ${TOKEN}`
      },
      body: JSON.stringify(BODY)
    }).then((response) => response.json())
      .then((responseJson) => {
        setLoading(false)
        setAllSalons(responseJson.Salons)
      })
      .catch((error) => {
        setLoading(false)
        alert(JSON.stringify(error));
      });

  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const search = (data) => {
    return data.filter((item) => item.name.toLowerCase().includes(query)
      ||
      item.gender.toLowerCase().includes(query)
      ||
      item.city.toLowerCase().includes(query)
      ||
      item.address.toLowerCase().includes(query)
    );
  }

  const handleDeleteUser = (userId) => {

    // const deleteUser = TABLE_DATA.filter((user) => user.id !== userId);
    // setTabledata(deleteUser);

  };

  const handleOwnerDetailsAlert = (SalonID) => {

    setLoading(true)

    const TOKEN = localStorage.getItem('currentUserAccessToken')
    const BODY = {
      assigned_salon: SalonID
    }

    fetch(`${BaseURL}/getUserDataBySalonID`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Bearer ${TOKEN}`
      },
      body: JSON.stringify(BODY)
    }).then((response) => response.json())
      .then((responseJson) => {

        setLoading(false)

        if (responseJson.Users.length === 0) {
          alert('Salon is not assign to any user')
          return;
        }

        confirmAlert({
          title: responseJson.Users[0].name,
          message: `Phone: ${responseJson.Users[0].phone} Email: ${responseJson.Users[0].email}`,
          buttons: [
            {
              label: 'ok',
              onClick: () => console.log('ok')
            },
          ]
        });

      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });

  };

  const setSalonIsFeaturedState = (SalonID, State) => {

    setLoading(true)

    const TOKEN = localStorage.getItem('currentUserAccessToken')
    const BODY = {
      salon_id: SalonID,
      isFeatured: State === "1" ? 0 : 1,
    }

    fetch(`${BaseURL}/updateSalonIsFeaturedBySalonID`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Bearer ${TOKEN}`
      },
      body: JSON.stringify(BODY)
    }).then((response) => response.json())
      .then((responseJson) => {

        setLoading(false)

        if (responseJson.status === 200) {
          getAllSalons();
        } else {
          alert(JSON.stringify(responseJson))
        }

      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });

  };

  const setSalonActiveState = (SalonID, State) => {

    setLoading(true)

    const TOKEN = localStorage.getItem('currentUserAccessToken')
    const BODY = {
      salon_id: SalonID,
      status: State === "1" ? 0 : 1,
    }

    fetch(`${BaseURL}/updateSalonActiveBySalonID`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Bearer ${TOKEN}`
      },
      body: JSON.stringify(BODY)
    }).then((response) => response.json())
      .then((responseJson) => {

        setLoading(false)

        if (responseJson.status === 200) {
          getAllSalons();
        } else {
          alert(JSON.stringify(responseJson))
        }

      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });

  };

  const handleClick = () => {

    if (salonImage.length === 0) {
      alert('Please Enter Salon Image')
    } else if (Name === "") {
      alert('Please Enter Salon Name')
    } else if (Latitude === "" || Latitude === 0) {
      alert('Please Enter Salon Latitude')
    } else if (Longitude === "" || Longitude === 0) {
      alert('Please Enter Salon Longitude')
    } else if (Address === "") {
      alert('Please Enter Salon Address')
    } else if (City === "") {
      alert('Please Enter Salon City')
    } else if (selectedGender.length === 0) {
      alert('Please Enter Salon Gender')
    } else if (selectedSalonTypes.length === 0) {
      alert('Please Enter Salon Types')
    } else if (selectedSalonComissionType === "") {
      alert('Please Select Salon Comission Type')
    } else if (salonComission === 0) {
      alert('Please Select Salon Comission')
    } else {

      setLoading(true)

      const TOKEN = localStorage.getItem('currentUserAccessToken')
      const IMAGE = salonImage

      const formData = new FormData()
      formData.append('salonImage', IMAGE)
      formData.append('name', Name)
      formData.append('latitude', Latitude)
      formData.append('longitude', Longitude)
      formData.append('address', Address)
      formData.append('type', selectedSalonTypes)
      formData.append('gender', selectedGender.value)
      formData.append('city', City)
      formData.append('comissionType', selectedSalonComissionType.value)
      formData.append('comission', salonComission)

      axios.post(`${BaseURL}/createNewSalon`, formData, {
        headers: {
          'Accept': 'application/json',
          'Content-type': 'multipart/form-data',
          'Authorization': `Bearer ${TOKEN}`
        }
      }).then((response) => {

        setLoading(false)

        if (response.status === 200) {
          getAllSalons()
          handleClose()
        } else {
          alert('Something went wrong please try again in a while')
        }

      }).catch((e) => {
        setLoading(false)
      })

    }
  };

  const handleChange = (event) => {
    const value = event.target.value
    setSelectedSalonTypes(value)
  }
  const handleChangeComissionType = (event) => {
    const value = event.target.value
    setSelectedSalonComissionType(value)
  }

  const handleFile = async (e) => {
    setSalonImage(e.target.files[0])
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - search(TABLE_DATA).length) : 0;
  const isNotFound = !TABLE_DATA.length && !!TABLE_DATA;

  return (
    <>
      <Helmet>
        <title> Salons | EaseSalotto </title>
      </Helmet>

      <Dialog
        scroll='body'
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <Card style={{ padding: 20 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>

              <Card style={{ marginBottom: 50 }}>
                <div style={{
                  padding: 10,
                  paddingTop: 30,
                  paddingBottom: 0,
                  backgroundColor: '#edf2f7',
                  borderStyle: 'dashed',
                  borderWidth: 2,
                  borderColor: '#cbd5e0',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}>

                  <Stack
                    spacing={2}
                    alignItems="center"
                    justifyContent="center"
                    direction={{ xs: 'column', md: 'row' }}
                    sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
                  >
                    <UploadIllustration sx={{ width: 220 }} />

                    <Box sx={{ p: 3 }}>
                      <Typography gutterBottom variant="h5">
                        Drop or Select file
                      </Typography>

                      <form method='POST'>
                        <input
                          accept="image/*"
                          style={{
                            marginBottom: -10,
                            opacity: 0
                          }}
                          id="contained-button-file"
                          type="file"
                          name="file"
                          onChange={handleFile}
                        />
                      </form>

                      <Typography style={{ marginTop: -20 }} variant="body2" sx={{ color: 'text.secondary' }}>
                        Drop files here or click&nbsp;
                        <Typography
                          variant="body2"
                          component="span"
                          sx={{ color: 'primary.main', textDecoration: 'underline' }}
                        >
                          browse
                        </Typography>
                        &nbsp;thorough your machine
                      </Typography>
                      <Typography>
                        {salonImage.name}
                      </Typography>
                    </Box>
                  </Stack>
                </div>
              </Card>

              <TextField style={{ margin: 5 }} fullWidth value={Name} onChange={(e) => setName(e.target.value)} name="icon" label="Name" />
              <TextField style={{ margin: 5 }} fullWidth value={Latitude} onChange={(e) => setLatitude(e.target.value)} name="icon" label="Latitude" />
              <TextField style={{ margin: 5 }} fullWidth value={Longitude} onChange={(e) => setLongitude(e.target.value)} name="icon" label="Longitude" />
              <TextField style={{ margin: 5 }} fullWidth value={Address} onChange={(e) => setAddress(e.target.value)} name="icon" label="Address" />
              <TextField select value={City} onChange={(e) => setCity(e.target.value)} style={{ margin: 5, marginTop: 10 }} fullWidth name="icon" label="City">
                {allCities.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              <TextField select value={selectedGender} onChange={(e) => setSelectedGender(e.target.value)} style={{ margin: 5, marginTop: 10 }} fullWidth name="icon" label="Gender">
                {gender.map((option) => (
                  <MenuItem key={option.value} value={option}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                select
                value={selectedSalonTypes}
                onChange={handleChange}
                SelectProps={{
                  multiple: true
                }}
                fullWidth
                style={{ margin: 5, marginTop: 10 }} name="icon" label="Salon Types">
                {salonTypes.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>

              <TextField select value={selectedSalonComissionType} onChange={(e) => setSelectedSalonComissionType(e.target.value)} style={{ margin: 5, marginTop: 10 }} fullWidth name="icon" label="Comission Type">
                {comissionTypes.map((option) => (
                  <MenuItem key={option.value} value={option}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField style={{ margin: 5 }} fullWidth value={salonComission} onChange={(e) => setSalonComission(e.target.value)} name="icon" label="Comission" />


              <LoadingButton onClick={() => handleClick()} style={{ width: 220, height: 55, margin: 5, marginTop: 10 }} fullWidth size="large" variant="contained">
                Create
              </LoadingButton>
            </Grid>
          </Grid>
        </Card>

        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Salons
          </Typography>
          {loading === true ?
            <Oval
              height={30}
              width={30}
              color="#ff7e00"
              wrapperStyle={{}}
              wrapperClass=""
              visible={"true"}
              ariaLabel='oval-loading'
              secondaryColor="#2e3a59"
              strokeWidth={2}
              strokeWidthSecondary={2}

            /> : null
          }
        </Stack>

        <div>
          <Card>

            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid xs={11} md={11}>
                <div style={{ marginTop: 20, marginBottom: 20, marginLeft: 40 }}>
                  <StyledSearch
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Search salon..."
                    startAdornment={
                      <InputAdornment position="start">
                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                      </InputAdornment>
                    }
                  />
                </div>
              </Grid>
              <Grid xs={1} md={1}>
                <Button variant="outlined" style={{ marginTop: 30 }} onClick={handleClickOpen}>
                  Create
                </Button>
              </Grid>
            </Grid>

            <Scrollbar>
              <TableContainer sx={{ minWidth: 720 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ minWidth: 160 }}>Image</TableCell>
                      <TableCell sx={{ minWidth: 160 }}>Name</TableCell>
                      <TableCell sx={{ minWidth: 120 }}>Latitude</TableCell>
                      <TableCell sx={{ minWidth: 200 }}>Longitude</TableCell>
                      <TableCell sx={{ minWidth: 120 }}>Address</TableCell>
                      <TableCell sx={{ minWidth: 120 }}>Type</TableCell>
                      <TableCell sx={{ minWidth: 120 }}>Gender</TableCell>
                      <TableCell sx={{ minWidth: 120 }}>City</TableCell>
                      <TableCell sx={{ minWidth: 120 }}>Com Type</TableCell>
                      <TableCell sx={{ minWidth: 120 }}>Comission</TableCell>
                      <TableCell sx={{ minWidth: 120 }}>Featured</TableCell>
                      <TableCell sx={{ minWidth: 120 }}>Status</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {search(TABLE_DATA).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {

                      const { id, image, name, latitude, longitude, address, type, gender, city, comissionType, comission, isFeatured, status } = row;

                      return (
                        <TableRow key={id}>
                          <TableCell> <Avatar alt={name} src={BaseURLImage + image} /></TableCell>
                          <TableCell>{name}</TableCell>
                          <TableCell>{latitude}</TableCell>
                          <TableCell>{longitude}</TableCell>
                          <TableCell>{address}</TableCell>
                          <TableCell>{type}</TableCell>
                          <TableCell>{gender}</TableCell>
                          <TableCell>{city}</TableCell>
                          <TableCell>{comissionType}</TableCell>
                          <TableCell>{comission}</TableCell>
                          <TableCell>
                            <Label color={(isFeatured === '0' && 'error') || 'success'}>{sentenceCase(isFeatured === '0' ? 'No' : 'Yes')}</Label>
                          </TableCell>
                          <TableCell align="left">
                            <Label color={(status === '1' && 'error') || 'success'}>{sentenceCase(status === '0' ? 'Active' : 'Block')}</Label>
                          </TableCell>
                          <TableCell align="right">
                            <SalonsTablePopUpMenu onOwnerClick={() => handleOwnerDetailsAlert(id)} onDelete={() => handleDeleteUser(id)} isFeatured={isFeatured} onIsFeaturedClick={() => setSalonIsFeaturedState(id, isFeatured)} isBlock={status} onIsBlockClick={() => setSalonActiveState(id, status)} userID={id} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}

                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{TABLE_DATA}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}

                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={search(TABLE_DATA).length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </div>
      </Container>


    </>
  );
}

