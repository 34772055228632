import { Helmet } from 'react-helmet-async';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Popover,
    TableRow,
    MenuItem,
    TableBody,
    TableHead,
    TableCell,
    Container,
    Typography,
    TextField,
    TableContainer,
    TablePagination,
    OutlinedInput,
    InputAdornment,
    Grid,
    Button,
    Dialog,
    DialogActions,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Oval } from 'react-loader-spinner'
// components
import { styled, alpha } from '@mui/material/styles';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import UserTablePopUpMenu from './Users/userTablePopUpMenu'
import { BaseURL } from '../common/Base_Url'


const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': {
        width: 320,
        boxShadow: theme.customShadows.z8,
    },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
    },
}));


export default function SalonOwnersPage() {

    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [query, setQuery] = useState('');
    const [Name, setName] = useState('');
    const [Email, setEmail] = useState('');
    const [Phone, setPhone] = useState('');
    const [selectedGender, setSelectedGender] = useState('');
    const [Password, setPaassword] = useState('');
    const [TABLE_DATA, setAllUsers] = useState([]);
    const [allSalons, setAllSalons] = useState([]);
    const [selectedSalon, setSelectedSalon] = useState([]);
    const [gender, setGender] = useState([
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' }
    ]);
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        getAllUsers()
        getAllSalons()
    }, []);

    const getAllUsers = () => {

        setLoading(true)

        const TOKEN = localStorage.getItem('currentUserAccessToken')

        const BODY = {}

        fetch(`${BaseURL}/getAllSalonOwners`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Bearer ${TOKEN}`
            },
            body: JSON.stringify(BODY)
        }).then((response) => response.json())
            .then((responseJson) => {

                setLoading(false)
                setAllUsers(responseJson.SalonOwners)

            })
            .catch((error) => {
                setLoading(false)
                alert(JSON.stringify(error));
            });

    };

    const getAllSalons = () => {

        setLoading(true)

        const TOKEN = localStorage.getItem('currentUserAccessToken')
        const BODY = {}

        fetch(`${BaseURL}/getAllSalons`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Bearer ${TOKEN}`
            },
            body: JSON.stringify(BODY)
        }).then((response) => response.json())
            .then((responseJson) => {
                setLoading(false)
                setAllSalons(responseJson.Salons)
            })
            .catch((error) => {
                setLoading(false)
                alert(JSON.stringify(error));
            });

    };

    const setUserActiveState = (USER_ID, STATE) => {

        // setLoading(true)

        const TOKEN = localStorage.getItem('currentUserAccessToken')
        const BODY = {
            id: USER_ID,
            isBlock: STATE === "1" ? 0 : 1,
        }

        fetch(`${BaseURL}/updateUserActiveByUserID`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Bearer ${TOKEN}`
            },
            body: JSON.stringify(BODY)
        }).then((response) => response.json())
            .then((responseJson) => {

                setLoading(false)

                if (responseJson.status === 200) {
                    getAllUsers();
                } else {
                    alert(JSON.stringify(responseJson))
                }

            })
            .catch((error) => {
                alert(JSON.stringify(error));
            });

    };

    const handleClick = () => {

        if (Name === "") {
            alert('Please Enter Owner Name')
        } else if (Email === "") {
            alert('Please Enter Owner Email')
        } else if (Phone === "") {
            alert('Please Enter Owner Phone')
        } else if (selectedGender.length === 0) {
            alert('Please Enter Owner Gender')
        } else if (Password === "") {
            alert('Please Enter Account Password')
        } else if (Password.length < 6) {
            alert('Please Enter Account Password Atlest 6 Digits')
        } else if (selectedSalon.length === 0) {
            alert('Please Select Assign Salon')
        } else {

            setLoading(true)

            const TOKEN = localStorage.getItem('currentUserAccessToken')

            const BODY = {
                name: Name,
                email: Email,
                phone: Phone,
                dob: 'null',
                gender: selectedGender.value,
                password: Password,
                password_confirmation: Password,
                user_type: 2,
                assigned_salon: selectedSalon.id,
                salonName: selectedSalon.name,
                referralCode: '!@!@!@!@!@()*&'
            }

            fetch(`${BaseURL}/register`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${TOKEN}`
                },
                body: JSON.stringify(BODY)
            }).then((response) => response.json())
                .then((responseJson) => {
                  
                    setLoading(false)

                    if (responseJson.email) {
                        alert("This email is already exist")
                    } else if (responseJson.phone) {
                        alert("This phone number is already exist")
                    } else if (responseJson.message === "User registered successfully") {
                        alert("User Register Successfully");
                        setName("")
                        setEmail("")
                        setPhone("")
                        setSelectedGender([])
                        setPaassword("")
                        setSelectedSalon([])
                        handleClose()
                        getAllSalons()
                        getAllUsers()
                    } else {
                        alert(JSON.stringify(responseJson))
                    }




                })
                .catch((error) => {
                    alert(JSON.stringify(error));
                });

        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const search = (data) => {
        return data.filter((item) => item.name.toLowerCase().includes(query)
            || item.email.toLowerCase().includes(query)
            || item.phone.toLowerCase().includes(query)
            || item.gender.toLowerCase().includes(query)
            || item.salonName.toLowerCase().includes(query)

        );
    }

    const handleDeleteUser = (userId) => {
        // const deleteUser = TABLE_DATA.filter((user) => user.id !== userId);
        // setTabledata(deleteUser);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - search(TABLE_DATA).length) : 0;
    const isNotFound = !TABLE_DATA.length && !!TABLE_DATA;

    return (
        <>
            <Helmet>
                <title> Salon Owners | EaseSalotto </title>
            </Helmet>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <Card style={{ padding: 20 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12}>

                            <TextField style={{ margin: 5 }} fullWidth value={Name} onChange={(e) => setName(e.target.value)} name="icon" label="Name" />
                            <TextField style={{ margin: 5 }} fullWidth value={Email} onChange={(e) => setEmail(e.target.value)} name="icon" label="Email" />
                            <TextField style={{ margin: 5 }} fullWidth value={Phone} onChange={(e) => setPhone(e.target.value)} name="icon" label="Phone" />
                            <TextField select value={selectedGender} onChange={(e) => setSelectedGender(e.target.value)} style={{ margin: 5, marginTop: 10 }} fullWidth name="icon" label="Gender">
                                {gender.map((option) => (
                                    <MenuItem key={option.value} value={option}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField style={{ margin: 5 }} fullWidth value={Password} onChange={(e) => setPaassword(e.target.value)} name="icon" label="Password" />
                            <TextField select value={selectedSalon.name} onChange={(e) => setSelectedSalon(e.target.value)} style={{ margin: 5, marginTop: 10 }} fullWidth name="icon" label="Salons">
                                {allSalons.map((option) => (
                                    <MenuItem key={option.id} value={option}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <LoadingButton onClick={() => handleClick()} style={{ width: 220, height: 55, margin: 5, marginTop: 10 }} fullWidth size="large" variant="contained">
                                Create
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Card>

                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Salon Owners
                    </Typography>
                    {loading === true ?
                        <Oval
                            height={30}
                            width={30}
                            color="#ff7e00"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={"true"}
                            ariaLabel='oval-loading'
                            secondaryColor="#2e3a59"
                            strokeWidth={2}
                            strokeWidthSecondary={2}

                        /> : null
                    }
                </Stack>

                <div>
                    <Card>

                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid xs={11} md={11}>
                                <div style={{ marginTop: 20, marginBottom: 20, marginLeft: 40 }}>
                                    <StyledSearch
                                        value={query}
                                        onChange={(e) => setQuery(e.target.value)}
                                        placeholder="Search user..."
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                                            </InputAdornment>
                                        }
                                    />
                                </div>
                            </Grid>
                            <Grid xs={1} md={1}>
                                <Button variant="outlined" style={{ marginTop: 30 }} onClick={handleClickOpen}>
                                    Create
                                </Button>
                            </Grid>
                        </Grid>

                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 720 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ minWidth: 160 }}>Assigned Salon</TableCell>
                                            <TableCell sx={{ minWidth: 160 }}>User Name</TableCell>
                                            <TableCell sx={{ minWidth: 160 }}>Email</TableCell>
                                            <TableCell sx={{ minWidth: 120 }}>Phone</TableCell>
                                            <TableCell sx={{ minWidth: 120 }}>Gender</TableCell>
                                            <TableCell sx={{ minWidth: 120 }}>Block</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {search(TABLE_DATA).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {

                                            const { id, name, email, phone, salonName, gender, isBlock } = row;

                                            return (
                                                <TableRow key={id}>
                                                    <TableCell>{salonName}</TableCell>
                                                    <TableCell>{name}</TableCell>
                                                    <TableCell>{email}</TableCell>
                                                    <TableCell>{phone}</TableCell>
                                                    <TableCell>{gender}</TableCell>
                                                    <TableCell align="left">
                                                        <Label color={(isBlock === '1' && 'error') || 'success'}>{sentenceCase(isBlock === '0' ? 'Active' : 'Block')}</Label>
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <UserTablePopUpMenu isBlock={isBlock} onIsBlockClick={() => setUserActiveState(id, isBlock)} onDelete={() => handleDeleteUser(id)} userID={id} />
                                                    </TableCell>

                                                </TableRow>
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}

                                    </TableBody>

                                    {isNotFound && (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                    <Paper
                                                        sx={{
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        <Typography variant="h6" paragraph>
                                                            Not found
                                                        </Typography>

                                                        <Typography variant="body2">
                                                            No results found for &nbsp;
                                                            <strong>&quot;{TABLE_DATA}&quot;</strong>.
                                                            <br /> Try checking for typos or using complete words.
                                                        </Typography>
                                                    </Paper>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}

                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={search(TABLE_DATA).length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Card>
                </div>
            </Container>

        </>
    );
}

