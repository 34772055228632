import PropTypes from 'prop-types';
import { paramCase } from 'change-case';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { MenuItem, IconButton } from '@mui/material';
// routes
// components
import Iconify from '../../components/iconify/Iconify';
import MenuPopover from '../../components/MenuPopover';
import { PATH_DASHBOARD } from '../../paths';

// ----------------------------------------------------------------------

SalonsTablePopUpMenu.propTypes = {
    onOwnerClick: PropTypes.func,
    onIsFeaturedClick: PropTypes.func,
    onIsBlockClick: PropTypes.func,
    onDelete: PropTypes.func,
    userName: PropTypes.string,
};

export default function SalonsTablePopUpMenu({ isFeatured, onIsFeaturedClick, isBlock, onIsBlockClick, onOwnerClick, onDelete, userID }) {

    const navigate = useNavigate();

    const [open, setOpen] = useState(null);

    const handleOpen = (event) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleMoveToBooking = (screen) => {
        navigate(`/dashboard/${screen}/${userID}`);
    };

    const ICON = {
        mr: 2,
        width: 20,
        height: 20,
    };

    return (
        <>
            <IconButton onClick={handleOpen}>
                <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
            </IconButton>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                arrow="right-top"
                sx={{
                    mt: -1,
                    width: 160,
                    '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
                }}
            >
                <MenuItem onClick={() => handleMoveToBooking('salonBilling')}>
                    <Iconify icon={'eva:file-fill'} sx={{ ...ICON }} />
                    Generate Bill
                </MenuItem>
                <MenuItem onClick={onOwnerClick}>
                    <Iconify icon={'eva:person-fill'} sx={{ ...ICON }} />
                    Owner Details
                </MenuItem>
                <MenuItem onClick={() => handleMoveToBooking('salonAccountContactInformation')}>
                    <Iconify icon={'eva:person-fill'} sx={{ ...ICON }} />
                    Account Info
                </MenuItem>



                <MenuItem onClick={() => handleMoveToBooking('salonBookings')}>
                    <Iconify icon={'eva:calendar-fill'} sx={{ ...ICON }} />
                    Bookings
                </MenuItem>

                <MenuItem onClick={() => handleMoveToBooking('salonCategories')}>
                    <Iconify icon={'eva:brush-fill'} sx={{ ...ICON }} />
                    Categories
                </MenuItem>

                <MenuItem onClick={() => handleMoveToBooking('salonServices')}>
                    <Iconify icon={'eva:brush-fill'} sx={{ ...ICON }} />
                    Services
                </MenuItem>

                <MenuItem onClick={() => handleMoveToBooking('SalonOffers')}>
                    <Iconify icon={'eva:percent-fill'} sx={{ ...ICON }} />
                    Offers
                </MenuItem>

                <MenuItem onClick={() => handleMoveToBooking('salonGallery')}>
                    <Iconify icon={'eva:image-fill'} sx={{ ...ICON }} />
                    Gallery
                </MenuItem>

                <MenuItem onClick={() => handleMoveToBooking('salonTimings')}>
                    <Iconify icon={'eva:clock-fill'} sx={{ ...ICON }} />
                    Timings
                </MenuItem>

                <MenuItem onClick={() => handleMoveToBooking('salonReviews')}>
                    <Iconify icon={'eva:star-fill'} sx={{ ...ICON }} />
                    Reviews
                </MenuItem>

                <MenuItem onClick={onIsFeaturedClick}>
                    <Iconify icon={'eva:star-fill'} sx={{ ...ICON }} />
                    isFeatured
                </MenuItem>

                <MenuItem onClick={onIsBlockClick} sx={{ color: 'error.main' }}>
                    <Iconify icon={'eva:close-outline'} sx={{ ...ICON }} />
                    Block
                </MenuItem>

            </MenuPopover>
        </>
    );
}
