import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { sentenceCase } from 'change-case';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
// @mui
import { useTheme, styled, alpha } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Box,
  Card,
  Stack,
  Table,
  Paper,
  Button,
  Divider,
  Menu,
  MenuItem,
  TableRow,
  TableBody,
  TableCell,
  IconButton,
  TableHead,
  TablePagination,
  CardHeader,
  TableContainer,
  OutlinedInput,
  InputAdornment
} from '@mui/material';
import Switch from '@mui/material/Switch';
import { Oval } from 'react-loader-spinner'
// components
import moment from 'moment/moment';
import Iconify from '../components/iconify';
import Label from '../components/label';
import Scrollbar from '../components/scrollbar';
import MenuPopover from '../components/MenuPopover';
import { BaseURL } from '../common/Base_Url'
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

const SORT_BY_OPTIONS = [
  { value: 'featured', label: 'Featured' },
  { value: 'newest', label: 'Newest' },
  { value: 'priceDesc', label: 'Price: High-Low' },
  { value: 'priceAsc', label: 'Price: Low-High' },
];

const label = { inputProps: { 'aria-label': 'Switch demo' } };

export default function DashboardAppPage() {

  const navigate = useNavigate();
  const theme = useTheme();
  const [loading, setLoading] = useState(false)
  const [usersCount, setUsersCount] = useState('0');
  const [salonsCount, setSalonsCount] = useState('0');
  const [revenueCount, setRevenueCount] = useState('0');
  const [bookingsCount, setBookingsCount] = useState('0');
  const [TABLE_DATA, setTodayBookings] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [query, setQuery] = useState('');
  const [open, setOpen] = useState(null);
  const [isTomorrow, setIsTomorrow] = useState(false);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };


  useEffect(() => {
    getDashboardCounts()
    getTodayBookings()
  }, []);


  const getDashboardCounts = () => {

    setLoading(true)

    const TOKEN = localStorage.getItem('currentUserAccessToken')

    const BODY = {}

    fetch(`${BaseURL}/dashboardCount`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Bearer ${TOKEN}`
      },
      body: JSON.stringify(BODY)
    }).then((response) => response.json())
      .then((responseJson) => {

        setLoading(false)

        setUsersCount(responseJson.Users)
        setSalonsCount(responseJson.Salons)
        setRevenueCount(responseJson.Revenue)
        setBookingsCount(responseJson.Bookings)

      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });

  };

  const getTodayBookings = () => {

    setLoading(true)

    const TOKEN = localStorage.getItem('currentUserAccessToken')

    const BODY = {
      booking_date: moment().format('YYYY-MM-DD')
    }

    fetch(`${BaseURL}/getBookingByTodayDate`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Bearer ${TOKEN}`
      },
      body: JSON.stringify(BODY)
    }).then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson)
        const DATA = []
        responseJson.Bookings.forEach(element => {
          DATA.push({
            id: element.id,
            transactionOrderId: element.transaction_order_id,
            username: element.username,
            usergender: element.usergender,
            userphone: element.userphone,
            bookingId: element.booking_id,
            salonName: JSON.parse(element.saloon_details),
            services: JSON.parse(element.booked_services),
            bookingDate: element.booking_date,
            bookingTime: element.booking_time,
            totalBill: element.total_bill,
            status: element.status
          })
        });

        setLoading(false)

        setTodayBookings(DATA)

      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });

  };

  const getTomorrowBookings = () => {

    setLoading(true)

    const TOKEN = localStorage.getItem('currentUserAccessToken')

    const BODY = {
      booking_date: moment().add(1, 'days').format('YYYY-MM-DD')
    }

    fetch(`${BaseURL}/getBookingByTodayDate`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Bearer ${TOKEN}`
      },
      body: JSON.stringify(BODY)
    }).then((response) => response.json())
      .then((responseJson) => {
        const DATA = []
        responseJson.Bookings.forEach(element => {
          const SALON_DETAILS = JSON.parse(element.saloon_details);
          DATA.push({
            id: element.id,
            salonid: SALON_DETAILS.id,
            transactionOrderId: element.transaction_order_id,
            username: element.username,
            usergender: element.usergender,
            userphone: element.userphone,
            bookingId: element.booking_id,
            salonName: JSON.parse(element.saloon_details),
            services: JSON.parse(element.booked_services),
            bookingDate: element.booking_date,
            bookingTime: element.booking_time,
            totalBill: element.total_bill,
            status: element.status
          })
        });

        setLoading(false)

        setTodayBookings(DATA)

      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });

  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const search = (data) => {
    return data.filter((item) => item.bookingId.toLowerCase().includes(query) || item.transactionOrderId.toLowerCase().includes(query))
  }

  const handleChange = (event) => {
    if (event.target.checked === true) {
      setIsTomorrow(event.target.checked);
      getTomorrowBookings()
    } else {
      setIsTomorrow(event.target.checked);
      getTodayBookings()
    }

  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - search(TABLE_DATA).length) : 0;
  const isNotFound = !TABLE_DATA.length && !!TABLE_DATA;

  return (
    <>
      <Helmet>
        <title> Dashboard | EaseSalotto </title>
      </Helmet>

      <Container maxWidth="xl">

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Hi, Welcome back
          </Typography>
          {loading === true ?
            <Oval
              height={30}
              width={30}
              color="#ff7e00"
              wrapperStyle={{}}
              wrapperClass=""
              visible={"true"}
              ariaLabel='oval-loading'
              secondaryColor="#2e3a59"
              strokeWidth={2}
              strokeWidthSecondary={2}

            /> : null
          }
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Users" total={usersCount} color="info" icon={'ant-design:user'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Salons" total={salonsCount} color="info" icon={'ant-design:home'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Total Revenue" total={revenueCount} color="info" icon={'ant-design:dollar'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Complete Bookings" total={bookingsCount} color="info" icon={'ant-design:calendar'} />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>

            <div>
              <Card>

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid xs={10} md={10}>
                    <div style={{ marginTop: 20, marginBottom: 20, marginLeft: 40 }}>
                      <StyledSearch
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        placeholder="Search booking..."
                        startAdornment={
                          <InputAdornment position="start">
                            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                          </InputAdornment>
                        }
                      />
                    </div>
                  </Grid>

                  <Grid xs={2} md={2}>
                    <div className='row' style={{ marginTop: 30 }}>
                      <span style={{ marginLeft: 10, fontWeight: 'bold' }}>{isTomorrow === false ? "Today" : "Tomorrow"}</span>
                      <Switch checked={isTomorrow} onChange={handleChange} />
                    </div>
                  </Grid>


                </Grid>

                <CardHeader title="New Bookings" sx={{ mb: 3 }} />

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 720 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ minWidth: 140 }}>Booking ID</TableCell>
                          <TableCell sx={{ minWidth: 140 }}>Transaction ID</TableCell>
                          <TableCell sx={{ minWidth: 140 }}>User Name</TableCell>
                          <TableCell sx={{ minWidth: 120 }}>User Gender</TableCell>
                          <TableCell sx={{ minWidth: 140 }}>User Phone</TableCell>
                          <TableCell sx={{ minWidth: 160 }}>Salon Name</TableCell>
                          <TableCell sx={{ minWidth: 120 }}>Services</TableCell>
                          <TableCell sx={{ minWidth: 140 }}>Booking Date</TableCell>
                          <TableCell sx={{ minWidth: 140 }}>Booking Time</TableCell>
                          <TableCell sx={{ minWidth: 140 }}>Total Bill</TableCell>
                          <TableCell sx={{ minWidth: 120 }}>Status</TableCell>
                          <TableCell sx={{ minWidth: 120 }}>Action</TableCell>
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>

                        {search(TABLE_DATA).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {

                          const { id, salonid, bookingId, transactionOrderId, bookingDate, bookingTime, salonName, services, status, totalBill, username, userphone, usergender } = row;

                          return (
                            <TableRow key={id}>
                              <TableCell>{bookingId}</TableCell>
                              <TableCell>{transactionOrderId}</TableCell>
                              <TableCell>{username}</TableCell>
                              <TableCell>{usergender}</TableCell>
                              <TableCell>{userphone}</TableCell>
                              <TableCell>{salonName.name}</TableCell>
                              <TableCell>
                                {services.map((item) => {
                                  return (
                                    <div style={{ margin: 5 }}>
                                      <Label color={'default'}>{sentenceCase(item.name)}</Label>
                                    </div>
                                  )
                                })}
                              </TableCell>
                              <TableCell>{bookingDate}</TableCell>
                              <TableCell>{bookingTime}</TableCell>
                              <TableCell>{totalBill}</TableCell>
                              <TableCell align="left">
                                <Label color={(status === 'cancel' && 'error') || 'success'}>{sentenceCase(status)}</Label>
                              </TableCell>
                              <TableCell>
                                <LoadingButton onClick={() => navigate(`/dashboard/salonAccountContactInformation/${salonid}`)} style={{ width: 100, height: 30, backgroundColor: '#FF7E00' }} fullWidth size="large" variant="contained">
                                  Info
                                </LoadingButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}

                      </TableBody>

                      {isNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                              <Paper
                                sx={{
                                  textAlign: 'center',
                                }}
                              >
                                <Typography variant="h6" paragraph>
                                  Not found
                                </Typography>

                                <Typography variant="body2">
                                  No results found for &nbsp;
                                  <strong>&quot;{TABLE_DATA}&quot;</strong>.
                                  <br /> Try checking for typos or using complete words.
                                </Typography>
                              </Paper>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}

                    </Table>
                  </TableContainer>
                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={search(TABLE_DATA).length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Card>
            </div>


          </Grid>

        </Grid>
      </Container>
    </>
  );
}
