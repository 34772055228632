import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { sentenceCase } from 'change-case';
// @mui
import {
    Box,
    Card,
    Table,
    Stack,
    Paper,
    Popover,
    TableRow,
    Avatar,
    MenuItem,
    TableBody,
    TableHead,
    TableCell,
    Container,
    Typography,
    IconButton,
    Button,
    TableContainer,
    TablePagination,
    OutlinedInput,
    InputAdornment,
} from '@mui/material';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import axios from 'axios';
import { Oval } from 'react-loader-spinner'
import { useParams } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import UploadIllustration from '../../assets/illustration_upload';
import { BaseURL, BaseURLImage } from '../../common/Base_Url'

const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.subtitle2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
}));

export default function SalonGallery() {
    const { id } = useParams();
    const [open, setOpen] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [query, setQuery] = useState('');
    const [loading, setLoading] = useState(false)

    const [TABLE_DATA, setTabledata] = useState([]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - TABLE_DATA.length) : 0;
    const isNotFound = !TABLE_DATA.length && !!TABLE_DATA;

    useEffect(() => {
        getSalonGallery();
    }, []);

    const getSalonGallery = () => {

        setLoading(true)

        const TOKEN = localStorage.getItem('currentUserAccessToken')
        const BODY = {
            salon_id: id
        }

        fetch(`${BaseURL}/getSalonGalleryAdmin`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Bearer ${TOKEN}`
            },
            body: JSON.stringify(BODY)
        }).then((response) => response.json())
            .then((responseJson) => {

                console.log(responseJson)

                const DATA = []
                responseJson.Gallery.forEach(element => {
                    DATA.push({
                        id: element.id,
                        salonID: element.salon_id,
                        image: element.salon_image,
                        date: element.created_at
                    })
                });

                setTabledata(DATA)

                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
                alert(JSON.stringify(error));
            });

    };

    const handleFile = async (e) => {

        setLoading(true)
        const TOKEN = localStorage.getItem('currentUserAccessToken')
        const IMAGE = e.target.files[0]

        const formData = new FormData()
        formData.append('salon_id', id)
        formData.append('salonImage', IMAGE)

        axios.post(`${BaseURL}/assignImageToSalon`, formData, {
            headers: {
                'Accept': 'application/json',
                'Content-type': 'multipart/form-data',
                'Authorization': `Bearer ${TOKEN}`
            }
        }).then((response) => {
            setLoading(false)
            if (response.status === 200) {
                getSalonGallery()
            } else {
                alert('Something went wrong please try again in a while')
            }

        }).catch((e)=>{
            setLoading(false)
        })
    }

    const handleDelete = (ID) => {

        setLoading(true)

        const TOKEN = localStorage.getItem('currentUserAccessToken')

        const BODY = {
            id: ID,
        }

        fetch(`${BaseURL}/deleteSalonImageAdmin`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Bearer ${TOKEN}`
            },
            body: JSON.stringify(BODY)
        }).then((response) => response.json())
            .then((responseJson) => {

                setLoading(false)


                if (responseJson.status === 200) {
                    getSalonGallery()
                } else if (responseJson.status === 400) {
                    alert("Image Not Found!")
                } else {
                    alert('Something went wrong please try in a while!')
                }

            })
            .catch((error) => {
                alert(JSON.stringify(error));
            });

    }

    return (
        <>
            <Helmet>
                <title> Salons | EaseSalotto </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Salon Gallery
                    </Typography>
                    {loading === true ?
                        <Oval
                            height={30}
                            width={30}
                            color="#ff7e00"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={"true"}
                            ariaLabel='oval-loading'
                            secondaryColor="#2e3a59"
                            strokeWidth={2}
                            strokeWidthSecondary={2}

                        /> : null
                    }
                </Stack>

                <Card style={{ marginBottom: 50 }}>
                    <div style={{
                        padding: 10,
                        paddingTop: 30,
                        paddingBottom: 30,
                        backgroundColor: '#edf2f7',
                        borderStyle: 'dashed',
                        borderWidth: 2,
                        borderColor: '#cbd5e0',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}>

                        <Stack
                            spacing={2}
                            alignItems="center"
                            justifyContent="center"
                            direction={{ xs: 'column', md: 'row' }}
                            sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
                        >
                            <UploadIllustration sx={{ width: 220 }} />

                            <Box sx={{ p: 3 }}>
                                <Typography gutterBottom variant="h5">
                                    Drop or Select file
                                </Typography>

                                <form method='POST'>
                                    <input
                                        accept="image/*"
                                        style={{
                                            marginBottom: -10,
                                            opacity: 0
                                        }}
                                        id="contained-button-file"
                                        type="file"
                                        name="file"
                                        onChange={handleFile}
                                    />
                                </form>

                                <Typography style={{ marginTop: -20 }} variant="body2" sx={{ color: 'text.secondary' }}>
                                    Drop files here or click&nbsp;
                                    <Typography
                                        variant="body2"
                                        component="span"
                                        sx={{ color: 'primary.main', textDecoration: 'underline' }}
                                    >
                                        browse
                                    </Typography>
                                    &nbsp;thorough your machine
                                </Typography>
                            </Box>
                        </Stack>


                    </div>
                </Card>

                <div>
                    <Card>

                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 720 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ minWidth: 240 }}>Image</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {TABLE_DATA.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {

                                            const { id, image } = row;

                                            return (
                                                <TableRow key={id}>
                                                    <TableCell> <Avatar style={{ width: 150, height: 150, borderRadius: 5 }} alt={id} src={BaseURLImage + image} /></TableCell>
                                                    <TableCell align="right">
                                                        <Button onClick={() =>
                                                            confirmAlert({
                                                                title: 'Confirm to submit',
                                                                message: 'Are you sure to delete this service?',
                                                                buttons: [
                                                                    {
                                                                        label: 'Yes',
                                                                        onClick: () => handleDelete(id)
                                                                    },
                                                                    {
                                                                        label: 'No',
                                                                        onClick: () => console.log('NO')
                                                                    }
                                                                ]
                                                            })} style={{ backgroundColor: 'red' }} variant="contained" startIcon={<Iconify icon="eva:close-fill" />}>
                                                            Delete
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}

                                    </TableBody>

                                    {isNotFound && (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                    <Paper
                                                        sx={{
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        <Typography variant="h6" paragraph>
                                                            Not found
                                                        </Typography>

                                                        <Typography variant="body2">
                                                            No results found for &nbsp;
                                                            <strong>&quot;{TABLE_DATA}&quot;</strong>.
                                                            <br /> Try checking for typos or using complete words.
                                                        </Typography>
                                                    </Paper>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}

                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={TABLE_DATA.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Card>
                </div>
            </Container>

        </>
    );
}
