import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { sentenceCase } from 'change-case';
// @mui
import {
    Grid,
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    TableRow,
    MenuItem,
    TableBody,
    TableHead,
    TableCell,
    Container,
    Typography,
    TextField,
    TableContainer,
    TablePagination,
    OutlinedInput,
    InputAdornment
} from '@mui/material';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Oval } from 'react-loader-spinner'
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { BaseURL } from '../../common/Base_Url'

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': {
        width: 320,
        boxShadow: theme.customShadows.z8,
    },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
    },
}));

export default function CustomerAppSupport() {
    const { id } = useParams();
    const [loading, setLoading] = useState(false)
    const [serviceName, setServiceName] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [query, setQuery] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [categories, setCategories] = useState([
        { value: 'latest', label: 'Latest' },
        { value: 'popular', label: 'Popular' },
        { value: 'oldest', label: 'Oldest' },
    ]);

    const [TABLE_DATA, setAllMessages] = useState([]);

    useEffect(() => {
        getAllServices()
    }, []);

    const getAllServices = () => {

        setLoading(true)

        const TOKEN = localStorage.getItem('currentUserAccessToken')
        const BODY = {}

        fetch(`${BaseURL}/getCustomerSupportMessages`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Bearer ${TOKEN}`
            },
        }).then((response) => response.json())
            .then((responseJson) => {

                const DATA = []
                responseJson.Messages.forEach(element => {
                    DATA.push({
                        id: element.id,
                        message: element.message,
                        date: element.created_at,
                        userID: element.user_id
                    })
                });

                setLoading(false)
                setAllMessages(DATA)
            })
            .catch((error) => {
                setLoading(false)
                alert(JSON.stringify(error));
            });

    };

    const handleDelete = (ID) => {

        setLoading(true)

        const TOKEN = localStorage.getItem('currentUserAccessToken')

        const BODY = {
            id: ID,
        }

        fetch(`${BaseURL}/deleteCustomerSupportMessage`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Bearer ${TOKEN}`
            },
            body: JSON.stringify(BODY)
        }).then((response) => response.json())
            .then((responseJson) => {

                setLoading(false)

                if (responseJson.status === 200) {
                    getAllServices()
                } else {
                    alert("Something Went Wrong Please Try Again");
                    getAllServices()
                }

            })
            .catch((error) => {
                alert(JSON.stringify(error));
            });

    }

    const handleUserDetails = (ID) => {

        setLoading(true)

        const TOKEN = localStorage.getItem('currentUserAccessToken')
        const BODY = {
            user_id: ID
        }

        fetch(`${BaseURL}/getUserByID`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Bearer ${TOKEN}`
            },
            body: JSON.stringify(BODY)
        }).then((response) => response.json())
            .then((responseJson) => {

                setLoading(false)

                confirmAlert({
                    title: responseJson.User[0].name,
                    message: `Phone: ${responseJson.User[0].phone} Email: ${responseJson.User[0].email} Gender: ${responseJson.User[0].gender} DOB: ${moment(responseJson.User[0].dob).format('LL')}`,
                    buttons: [
                        {
                            label: 'ok',
                            onClick: () => console.log('ok')
                        },
                    ]
                });

            })
            .catch((error) => {
                alert(JSON.stringify(error));
            });

    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const search = (data) => {
        return data.filter((item) => item.date.includes(query))
    }


    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - search(TABLE_DATA).length) : 0;
    const isNotFound = !TABLE_DATA.length && !!TABLE_DATA;

    return (
        <>
            <Helmet>
                <title> Customer Support | EaseSalotto </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Customer Support
                    </Typography>
                    {loading === true ?
                        <Oval
                            height={30}
                            width={30}
                            color="#ff7e00"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={"true"}
                            ariaLabel='oval-loading'
                            secondaryColor="#2e3a59"
                            strokeWidth={2}
                            strokeWidthSecondary={2}

                        /> : null
                    }
                </Stack>

                <div style={{ marginTop: 20 }}>
                    <Card>

                        {/* <div style={{ marginTop: 20, marginBottom: 20, marginLeft: 20 }}>
                            <StyledSearch
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                placeholder="Search service..."
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                                    </InputAdornment>
                                }
                            />
                        </div> */}

                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 720 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ minWidth: 240 }}>Message</TableCell>
                                            <TableCell sx={{ minWidth: 160 }}>Date</TableCell>
                                            <TableCell sx={{ minWidth: 160 }}>User</TableCell>
                                            <TableCell sx={{ minWidth: 160 }}>Action</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {search(TABLE_DATA).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {

                                            const { id, userID, message, date } = row;

                                            return (
                                                <TableRow key={id}>
                                                    <TableCell>{message}</TableCell>
                                                    <TableCell>{moment(date).format('LL')}</TableCell>
                                                    <TableCell>
                                                        <LoadingButton onClick={() => handleUserDetails(userID)} style={{ width: 100, height: 30, backgroundColor: '#FF7E00' }} fullWidth size="large" type="submit" variant="contained">
                                                            User
                                                        </LoadingButton>
                                                    </TableCell>
                                                    <TableCell>
                                                        <LoadingButton onClick={() =>
                                                            confirmAlert({
                                                                title: 'Confirm to submit',
                                                                message: 'Are you sure to delete this message?',
                                                                buttons: [
                                                                    {
                                                                        label: 'Yes',
                                                                        onClick: () => handleDelete(id)
                                                                    },
                                                                    {
                                                                        label: 'No',
                                                                        onClick: () => console.log('NO')
                                                                    }
                                                                ]
                                                            })} style={{ width: 100, height: 30, backgroundColor: 'red' }} fullWidth size="large" type="submit" variant="contained">
                                                            Delete
                                                        </LoadingButton>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}

                                    </TableBody>

                                    {isNotFound && (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                    <Paper
                                                        sx={{
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        <Typography variant="h6" paragraph>
                                                            Not found
                                                        </Typography>

                                                        <Typography variant="body2">
                                                            No results found for &nbsp;
                                                            <strong>&quot;{TABLE_DATA}&quot;</strong>.
                                                            <br /> Try checking for typos or using complete words.
                                                        </Typography>
                                                    </Paper>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}

                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={search(TABLE_DATA).length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Card>
                </div>
            </Container>

        </>
    );
}
