import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';

import UserPage from './pages/UserPage';
import SalonOwnersPage from './pages/SalonOwnersPage';
import UserBookings from './pages/Users/userBookings';


import SalonPage from './pages/SalonPage';
import SalonBookings from './pages/Salons/salonBookings';
import SalonGallery from './pages/Salons/salonGallery';
import SalonOffers from './pages/Salons/salonOffers';
import SalonTimings from './pages/Salons/salonTimings';
import SalonReviews from './pages/Salons/salonReviews';
import SalonServices from './pages/Salons/salonServices';
import SalonCategories from './pages/Salons/salonCategories';
import SalonAccountContactInformation from './pages/Salons/salonAccountContactInformation';
import SalonBilling from './pages/Salons/salonBilling';

// Support Messages
import CustomerAppSupport from './pages/Support/CustomerAppSupport';
import SalonAppSupport from './pages/Support/SalonAppSupport';
import WebsiteSupport from './pages/Support/WebsiteSupport';

import CategoryPage from './pages/CategoryPage';
import ServicesPage from './pages/ServicesPage';
import BookingPage from './pages/BookingPage';
import Promotions from './pages/Promotions';
import ReportPage from './pages/ReportPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/',
      element: <LoginPage />,
      index: true
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },

        // Users Routes Start
        { path: 'user', element: <UserPage /> },
        { path: 'userBookings/:id', element: <UserBookings /> },

        // Users Routes End

        // Salon Owner Routes Start
        { path: 'salonOwners', element: <SalonOwnersPage /> },
        // Salon Owner Routes End

        // Salon Routes Start
        { path: 'salons', element: <SalonPage /> },
        { path: 'salonBookings/:id', element: <SalonBookings /> },
        { path: 'salonCategories/:id', element: <SalonCategories /> },
        { path: 'salonAccountContactInformation/:id', element: <SalonAccountContactInformation /> },
        { path: 'salonBilling/:id', element: <SalonBilling /> },
        { path: 'salonServices/:id', element: <SalonServices /> },
        { path: 'salonGallery/:id', element: <SalonGallery /> },
        { path: 'SalonOffers/:id', element: <SalonOffers /> },
        { path: 'SalonTimings/:id', element: <SalonTimings /> },
        { path: 'SalonReviews/:id', element: <SalonReviews /> },
        // Salon Routes End

        // Support Messages
        { path: 'customersppsupport', element: <CustomerAppSupport /> },
        { path: 'salonppsupport', element: <SalonAppSupport /> },
        { path: 'websitesupport', element: <WebsiteSupport /> },

        { path: 'category', element: <CategoryPage /> },
        { path: 'services', element: <ServicesPage /> },
        { path: 'bookings', element: <BookingPage /> },
        { path: 'promotions', element: <Promotions /> },
        { path: 'reports', element: <ReportPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
