import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { sentenceCase } from 'change-case';
// @mui
import {
  Grid,
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  TableRow,
  MenuItem,
  TableBody,
  TableHead,
  TableCell,
  Container,
  Typography,
  TextField,
  TableContainer,
  TablePagination,
  OutlinedInput,
  InputAdornment
} from '@mui/material';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Oval } from 'react-loader-spinner'
import { LoadingButton } from '@mui/lab';
import { useParams } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { BaseURL } from '../../common/Base_Url'

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

export default function SalonServices() {
  const { id } = useParams();

  const [allCategories, setAllCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState([]);

  const [allServices, setAllServices] = useState([])
  const [selectedServices, setSelectedServices] = useState([]);

  const [time, setTime] = useState("");
  const [cost, setCost] = useState("");

  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [query, setQuery] = useState('');

  const [TABLE_DATA, setTabledata] = useState([]);

  useEffect(() => {
    getAllCategories();
    getAllServices();
    getAllAssignServices();
  }, []);

  const getAllCategories = () => {

    setLoading(true)

    const TOKEN = localStorage.getItem('currentUserAccessToken')
    const BODY = {
      salon_id: id
    }

    fetch(`${BaseURL}/getAllCategoryToSalonAdmin`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Bearer ${TOKEN}`
      },
      body: JSON.stringify(BODY)
    }).then((response) => response.json())
      .then((responseJson) => {

        const DATA = []
        responseJson.Service.forEach(element => {
          DATA.push({
            id: element.category_id,
            name: element.category_name
          })
        });

        setAllCategories(DATA)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        alert(JSON.stringify(error));
      });

  };
  const getAllServices = () => {

    setLoading(true)

    const TOKEN = localStorage.getItem('currentUserAccessToken')
    const BODY = {}

    fetch(`${BaseURL}/getAllServicesAdmin`, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Bearer ${TOKEN}`
      },
    }).then((response) => response.json())
      .then((responseJson) => {

        setAllServices(responseJson.Services)

        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        alert(JSON.stringify(error));
      });

  };

  const getAllAssignServices = () => {

    setLoading(true)

    const TOKEN = localStorage.getItem('currentUserAccessToken')
    const BODY = {
      salon_id: id
    }

    fetch(`${BaseURL}/getAssignServicesToSalonAdmin`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Bearer ${TOKEN}`
      },
      body: JSON.stringify(BODY)
    }).then((response) => response.json())
      .then((responseJson) => {

        setTabledata(responseJson.Services)

        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        alert(JSON.stringify(error));
      });

  };

  const handleClick = () => {

    if (selectedCategory.length === 0) {
      alert('Please Select Category')
    } else if (selectedServices.length === 0) {
      alert('Please Select Service')
    } else if (time === "") {
      alert('Please Enter Service Time')
    } else if (cost === "") {
      alert('Please Enter Service Cost')
    } else {

      setLoading(true)

      const TOKEN = localStorage.getItem('currentUserAccessToken')

      const BODY = {
        salon_id: id,
        name: selectedServices.name,
        time,
        cost: parseFloat(cost).toFixed(0),
        categoryId: selectedCategory.id,
        categoryName: selectedCategory.name,
      }

      fetch(`${BaseURL}/assignServiceToSalonAdmin`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-type': 'application/json',
          'Authorization': `Bearer ${TOKEN}`
        },
        body: JSON.stringify(BODY)
      }).then((response) => response.json())
        .then((responseJson) => {

          setLoading(false)

          if (responseJson.status === 200) {
            getAllCategories()
            getAllServices()
            getAllAssignServices()
          } else {
            alert(JSON.stringify(responseJson))
            // alert("Something Went Wrong Please Try Again");
            getAllCategories()
            getAllServices()
            getAllAssignServices()
          }

        })
        .catch((error) => {
          alert(JSON.stringify(error));
        });

    }
  };

  const handleDelete = (ID) => {

    setLoading(true)

    const TOKEN = localStorage.getItem('currentUserAccessToken')

    const BODY = {
      id: ID,
    }

    fetch(`${BaseURL}/deleteAssignServicesToSalonAdmin`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-type': 'application/json',
        'Authorization': `Bearer ${TOKEN}`
      },
      body: JSON.stringify(BODY)
    }).then((response) => response.json())
      .then((responseJson) => {

        setLoading(false)

        if (responseJson.status === 200) {
          getAllCategories();
          getAllServices();
          getAllAssignServices();
        } else {
          alert("Something Went Wrong Please Try Again");
          getAllCategories();
          getAllServices();
          getAllAssignServices();
        }

      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });

  }



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const search = (data) => {
    return data.filter((item) => item.name.toLowerCase().includes(query))
  }


  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - search(TABLE_DATA).length) : 0;
  const isNotFound = !TABLE_DATA.length && !!TABLE_DATA;

  return (
    <>
      <Helmet>
        <title> Services | EaseSalotto </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Services
          </Typography>
          {loading === true ?
            <Oval
              height={30}
              width={30}
              color="#ff7e00"
              wrapperStyle={{}}
              wrapperClass=""
              visible={"true"}
              ariaLabel='oval-loading'
              secondaryColor="#2e3a59"
              strokeWidth={2}
              strokeWidthSecondary={2}

            /> : null
          }
        </Stack>

        <Card style={{ padding: 20 }}>
          <Grid container spacing={3}>

            <Grid item xs={12} sm={12} md={12}>
              <TextField select value={selectedCategory.name} onChange={(e) => setSelectedCategory(e.target.value)} style={{ marginLeft: 10, width: 150 }} name="icon" label="Category">
                {allCategories.map((option) => (
                  <MenuItem key={option.id} value={option}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField select value={selectedServices.name} onChange={(e) => setSelectedServices(e.target.value)} style={{ marginLeft: 10, width: 150 }} name="icon" label="Service">
                {allServices.map((option) => (
                  <MenuItem key={option.id} value={option}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField style={{ marginLeft: 10 }} value={time} onChange={(e) => setTime(e.target.value)} name="icon" label="Service Time" />
              <TextField style={{ marginLeft: 10 }} value={cost} onChange={(e) => setCost(e.target.value)} name="icon" label="Service Cost" />

              <LoadingButton onClick={() => handleClick()} style={{ width: 220, height: 55, marginLeft: 20 }} fullWidth size="large" variant="contained">
                Save
              </LoadingButton>
            </Grid>
          </Grid>
        </Card>

        <div style={{ marginTop: 20 }}>
          <Card>

            <div style={{ marginTop: 20, marginBottom: 20, marginLeft: 20 }}>
              <StyledSearch
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Search service..."
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
                }
              />
            </div>

            <Scrollbar>
              <TableContainer sx={{ minWidth: 720 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ minWidth: 240 }}>Name</TableCell>
                      <TableCell sx={{ minWidth: 160 }}>Time</TableCell>
                      <TableCell sx={{ minWidth: 160 }}>Cost</TableCell>
                      <TableCell sx={{ minWidth: 160 }}>Category</TableCell>
                      <TableCell sx={{ minWidth: 160 }}>Action</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {search(TABLE_DATA).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {

                      const { id, name, time, cost, categoryName } = row;

                      return (
                        <TableRow key={id}>
                          <TableCell>{name}</TableCell>
                          <TableCell>{time}</TableCell>
                          <TableCell>{cost}</TableCell>
                          <TableCell>{categoryName}</TableCell>
                          <TableCell>
                            <LoadingButton onClick={() =>
                              confirmAlert({
                                title: 'Confirm to submit',
                                message: 'Are you sure to delete this service?',
                                buttons: [
                                  {
                                    label: 'Yes',
                                    onClick: () => handleDelete(id)
                                  },
                                  {
                                    label: 'No',
                                    onClick: () => console.log('NO')
                                  }
                                ]
                              })} style={{ width: 100, height: 30, backgroundColor: 'red' }} fullWidth size="large" variant="contained">
                              Delete
                            </LoadingButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}

                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{TABLE_DATA}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}

                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={search(TABLE_DATA).length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </div>
      </Container>

    </>
  );
}
