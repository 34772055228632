import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async';
import { sentenceCase } from 'change-case';
// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    TableRow,
    TableBody,
    TableHead,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    OutlinedInput,
    InputAdornment
} from '@mui/material';
import { Oval } from 'react-loader-spinner'
import { useParams } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import moment from 'moment';
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
import { BaseURL } from '../../common/Base_Url'

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': {
        width: 320,
        boxShadow: theme.customShadows.z8,
    },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
    },
}));

export default function SalonReviews() {
    const { id } = useParams();
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [query, setQuery] = useState('');

    const [TABLE_DATA, setTabledata] = useState([]);

    useEffect(() => {
        getAllReviews()
    }, [])

    const getAllReviews = () => {

        setLoading(true)

        const TOKEN = localStorage.getItem('currentUserAccessToken')

        const BODY = {
            salon_id: id
        }

        fetch(`${BaseURL}/getSalonReviewsAdmin`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-type': 'application/json',
                'Authorization': `Bearer ${TOKEN}`
            },
            body: JSON.stringify(BODY)
        }).then((response) => response.json())
            .then((responseJson) => {

                const DATA = []
                responseJson.Reviews.forEach(element => {
                    DATA.push({
                        id: element.id,
                        username: element.username,
                        comment: element.comment,
                        rating: element.rating,
                        createdAt: moment(element.created_at).format('LL'),
                    })
                });

                setLoading(false)

                setTabledata(DATA)
            })
            .catch((error) => {
                alert(JSON.stringify(error));
            });

    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const search = (data) => {
        return data.filter((item) => item.username.toLowerCase().includes(query) || item.rating.toLowerCase().includes(query) || item.createdAt.toLowerCase().includes(query))
    }

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - search(TABLE_DATA).length) : 0;
    const isNotFound = !TABLE_DATA.length && !!TABLE_DATA;

    return (
        <>
            <Helmet>
                <title> Salons | EaseSalotto </title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Salon Reviews
                    </Typography>
                    {loading === true ?
                        <Oval
                            height={30}
                            width={30}
                            color="#ff7e00"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={"true"}
                            ariaLabel='oval-loading'
                            secondaryColor="#2e3a59"
                            strokeWidth={2}
                            strokeWidthSecondary={2}

                        /> : null
                    }
                </Stack>

                <div>
                    <Card>

                        <div style={{ marginTop: 20, marginBottom: 20, marginLeft: 20 }}>
                            <StyledSearch
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                placeholder="Search..."
                                startAdornment={
                                    <InputAdornment position="start">
                                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                                    </InputAdornment>
                                }
                            />
                        </div>

                        <Scrollbar>
                            <TableContainer sx={{ minWidth: 720 }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ minWidth: 240 }}>Username</TableCell>
                                            <TableCell sx={{ minWidth: 160 }}>Comment</TableCell>
                                            <TableCell sx={{ minWidth: 120 }}>Rating</TableCell>
                                            <TableCell sx={{ minWidth: 120 }}>Date</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {search(TABLE_DATA).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {

                                            const { id, username, comment, rating, createdAt } = row;

                                            return (
                                                <TableRow key={id}>
                                                    <TableCell>{username}</TableCell>
                                                    <TableCell>{comment}</TableCell>
                                                    <TableCell align="left">
                                                        <Label color={'success'}>{sentenceCase(rating)}</Label>
                                                    </TableCell>
                                                    <TableCell>{createdAt}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}

                                    </TableBody>

                                    {isNotFound && (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                    <Paper
                                                        sx={{
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        <Typography variant="h6" paragraph>
                                                            Not found
                                                        </Typography>

                                                        <Typography variant="body2">
                                                            No results found for &nbsp;
                                                            <strong>&quot;{TABLE_DATA}&quot;</strong>.
                                                            <br /> Try checking for typos or using complete words.
                                                        </Typography>
                                                    </Paper>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )}

                                </Table>
                            </TableContainer>
                        </Scrollbar>

                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={search(TABLE_DATA).length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Card>
                </div>
            </Container>

        </>
    );
}
