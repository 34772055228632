import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { sentenceCase } from 'change-case';
// @mui
import {
  Grid,
  Card,
  Table,
  Stack,
  Paper,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableHead,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  OutlinedInput,
  InputAdornment
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';

import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 320,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

export default function BookingPage() {
  const { id } = useParams();
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [query, setQuery] = useState('');

  const [TABLE_DATA, setTabledata] = useState([
    { id: 1, username: 'Zouraiz Khan', bookingId: 'S35XIDF', salonName: 'London Salon', services: [{ id: 4, name: "Skin Acne Treatment", time: "2h", cost: "300", description: null, category_id: "3" }, { id: 3, name: "Christmas Makeup Look", time: "1h", cost: "100", description: null, category_id: "2" }, { id: 1, name: "Hair Color Half Head", time: "30 Min", cost: "30", description: null, category_id: "1" }, { id: 2, name: "Hair Color Full", time: "60 Min", cost: "60", description: null, category_id: "1" }], bookingDate: '2022-10-30', bookingTime: '11:45 PM', totalBill: '460', status: 'complete' },
    { id: 1, username: 'Zouraiz Khan', bookingId: 'S35XIDF', salonName: 'London Salon', services: [{ id: 4, name: "Skin Acne Treatment", time: "2h", cost: "300", description: null, category_id: "3" }, { id: 3, name: "Christmas Makeup Look", time: "1h", cost: "100", description: null, category_id: "2" }, { id: 1, name: "Hair Color Half Head", time: "30 Min", cost: "30", description: null, category_id: "1" }, { id: 2, name: "Hair Color Full", time: "60 Min", cost: "60", description: null, category_id: "1" }], bookingDate: '2022-10-30', bookingTime: '11:45 PM', totalBill: '460', status: 'complete' },
    { id: 1, username: 'Zouraiz Khan', bookingId: 'S35XIDF', salonName: 'London Salon', services: [{ id: 4, name: "Skin Acne Treatment", time: "2h", cost: "300", description: null, category_id: "3" }, { id: 3, name: "Christmas Makeup Look", time: "1h", cost: "100", description: null, category_id: "2" }, { id: 1, name: "Hair Color Half Head", time: "30 Min", cost: "30", description: null, category_id: "1" }, { id: 2, name: "Hair Color Full", time: "60 Min", cost: "60", description: null, category_id: "1" }], bookingDate: '2022-10-30', bookingTime: '11:45 PM', totalBill: '460', status: 'complete' },
    { id: 1, username: 'Zouraiz Khan', bookingId: 'S35XIDF', salonName: 'London Salon', services: [{ id: 4, name: "Skin Acne Treatment", time: "2h", cost: "300", description: null, category_id: "3" }, { id: 3, name: "Christmas Makeup Look", time: "1h", cost: "100", description: null, category_id: "2" }, { id: 1, name: "Hair Color Half Head", time: "30 Min", cost: "30", description: null, category_id: "1" }, { id: 2, name: "Hair Color Full", time: "60 Min", cost: "60", description: null, category_id: "1" }], bookingDate: '2022-10-30', bookingTime: '11:45 PM', totalBill: '460', status: 'complete' },
    { id: 1, username: 'Zouraiz Khan', bookingId: 'S35XIDF', salonName: 'London Salon', services: [{ id: 4, name: "Skin Acne Treatment", time: "2h", cost: "300", description: null, category_id: "3" }, { id: 3, name: "Christmas Makeup Look", time: "1h", cost: "100", description: null, category_id: "2" }, { id: 1, name: "Hair Color Half Head", time: "30 Min", cost: "30", description: null, category_id: "1" }, { id: 2, name: "Hair Color Full", time: "60 Min", cost: "60", description: null, category_id: "1" }], bookingDate: '2022-10-30', bookingTime: '11:45 PM', totalBill: '460', status: 'complete' },
    { id: 1, username: 'Zouraiz Khan', bookingId: 'S35XIDF', salonName: 'London Salon', services: [{ id: 4, name: "Skin Acne Treatment", time: "2h", cost: "300", description: null, category_id: "3" }, { id: 3, name: "Christmas Makeup Look", time: "1h", cost: "100", description: null, category_id: "2" }, { id: 1, name: "Hair Color Half Head", time: "30 Min", cost: "30", description: null, category_id: "1" }, { id: 2, name: "Hair Color Full", time: "60 Min", cost: "60", description: null, category_id: "1" }], bookingDate: '2022-10-30', bookingTime: '11:45 PM', totalBill: '460', status: 'complete' },
    { id: 1, username: 'Zouraiz Khan', bookingId: 'S35XIDF', salonName: 'London Salon', services: [{ id: 4, name: "Skin Acne Treatment", time: "2h", cost: "300", description: null, category_id: "3" }, { id: 3, name: "Christmas Makeup Look", time: "1h", cost: "100", description: null, category_id: "2" }, { id: 1, name: "Hair Color Half Head", time: "30 Min", cost: "30", description: null, category_id: "1" }, { id: 2, name: "Hair Color Full", time: "60 Min", cost: "60", description: null, category_id: "1" }], bookingDate: '2022-10-30', bookingTime: '11:45 PM', totalBill: '460', status: 'complete' },
    { id: 1, username: 'Zouraiz Khan', bookingId: 'S35XIDF', salonName: 'London Salon', services: [{ id: 4, name: "Skin Acne Treatment", time: "2h", cost: "300", description: null, category_id: "3" }, { id: 3, name: "Christmas Makeup Look", time: "1h", cost: "100", description: null, category_id: "2" }, { id: 1, name: "Hair Color Half Head", time: "30 Min", cost: "30", description: null, category_id: "1" }, { id: 2, name: "Hair Color Full", time: "60 Min", cost: "60", description: null, category_id: "1" }], bookingDate: '2022-10-30', bookingTime: '11:45 PM', totalBill: '460', status: 'complete' },
    { id: 1, username: 'Zouraiz Khan', bookingId: 'S35XIDF', salonName: 'London Salon', services: [{ id: 4, name: "Skin Acne Treatment", time: "2h", cost: "300", description: null, category_id: "3" }, { id: 3, name: "Christmas Makeup Look", time: "1h", cost: "100", description: null, category_id: "2" }, { id: 1, name: "Hair Color Half Head", time: "30 Min", cost: "30", description: null, category_id: "1" }, { id: 2, name: "Hair Color Full", time: "60 Min", cost: "60", description: null, category_id: "1" }], bookingDate: '2022-10-30', bookingTime: '11:45 PM', totalBill: '460', status: 'complete' },

  ]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const search = (data) => {
    return data.filter((item) => item.bookingId.toLowerCase().includes(query))
  }


  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - search(TABLE_DATA).length) : 0;
  const isNotFound = !TABLE_DATA.length && !!TABLE_DATA;


  return (
    <>
      <Helmet>
        <title> Bookings | EaseSalotto </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            All Bookings
          </Typography>
        </Stack>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Completed Bookings" total={10} color="info" icon={'ant-design:calendar'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="In-Process Bookings" total={2} color="info" icon={'ant-design:calendar'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Upcoming Bookings" total={7} color="info" icon={'ant-design:calendar'} />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Cancelled Bookings" total={3} color="info" icon={'ant-design:calendar'} />
          </Grid>
        </Grid>

        <div style={{ marginTop: 20 }}>
          <Card>

            <div style={{ marginTop: 20, marginBottom: 20, marginLeft: 20 }}>
              <StyledSearch
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Search user..."
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                  </InputAdornment>
                }
              />
            </div>

            <Scrollbar>
              <TableContainer sx={{ minWidth: 720 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ minWidth: 240 }}>Booking ID</TableCell>
                      <TableCell sx={{ minWidth: 160 }}>User Name</TableCell>
                      <TableCell sx={{ minWidth: 160 }}>Salon Name</TableCell>
                      <TableCell sx={{ minWidth: 120 }}>Services</TableCell>
                      <TableCell sx={{ minWidth: 200 }}>Booking Date</TableCell>
                      <TableCell sx={{ minWidth: 200 }}>Booking Time</TableCell>
                      <TableCell sx={{ minWidth: 200 }}>Total Bill</TableCell>
                      <TableCell sx={{ minWidth: 120 }}>Status</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>

                    {search(TABLE_DATA).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {

                      const { id, bookingId, username, salonName, services, bookingDate, bookingTime, totalBill, status } = row;

                      return (
                        <TableRow key={id}>
                          <TableCell>{bookingId}</TableCell>
                          <TableCell>{username}</TableCell>
                          <TableCell>{salonName}</TableCell>
                          <TableCell>
                            {services.map((item) => {
                              return (
                                <div style={{ margin: 5 }}>
                                  <Label color={'default'}>{sentenceCase(item.name)}</Label>
                                </div>
                              )
                            })}
                          </TableCell>
                          <TableCell>{bookingDate}</TableCell>
                          <TableCell>{bookingTime}</TableCell>
                          <TableCell>{totalBill}</TableCell>
                          <TableCell align="left">
                            <Label color={(status === 'cancel' && 'error') || 'success'}>{sentenceCase(status)}</Label>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}

                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{TABLE_DATA}&quot;</strong>.
                              <br /> Try checking for typos or using complete words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}

                </Table>
              </TableContainer>
            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={search(TABLE_DATA).length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </div>
      </Container>

    </>
  );
}
